import i18n from 'i18n-js';
import { Positions } from 'constants/enums';

import { pagingOptions } from 'constants/arrays';
import { TaxType } from 'constants/enums';

export function pickImage(e: any): Promise<{
    errorMessage?: string;
    file?: File;
}> {
    return new Promise((resolve, reject) => {
        const file = e.target.files[0];

        if (!file) {
            resolve({
                errorMessage: i18n.t('warnings.imageSelectionCancelled'),
            });
        }

        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        if (file && !acceptedImageTypes.includes(file['type'])) {
            resolve({
                errorMessage: i18n.t('warnings.fileShouldBeImage'),
            });
        }
        const size = parseFloat((file.size / (1024 * 1024)).toFixed(2));
        if (size > 1) {
            resolve({
                errorMessage: i18n.t('warnings.imageSizeWarning'),
            });
        }
        let img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            const width = img.width,
                height = img.height;

            file.width = width;
            file.height = height;
            file.whRatio = (width / height).toFixed(3);

            resolve({
                file,
            });
        };
    });
}

export function renderMultipleClassNames(classNames: string[]) {
    return classNames.join(' ');
}

export function getDynamicRowLength(text: string | undefined, divideLength = 30) {
    if (text) {
        return Math.round(text.length / divideLength);
    }
    return 1;
}

/**
 * maxWidth or maxHeight controls the image dimensions to be proper.
 * if no maxWidth or maxHeight passed, function will return proper sizes depending on the parent container
 * @param maxWidth
 * @param maxHeight
 */
export function getDynamicImageSize(maxWidth?: number | null, maxHeight?: number | null) {
    if (maxHeight) {
        return {
            height: `${maxHeight}px`,
        };
    }

    if (maxWidth) {
        return {
            width: `${maxWidth}px`,
        };
    }

    return {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    } as any;
}

/**
 * address param is passed and with google api we retrieve latitude and longitude of the given address.
 * if no response retrieved we just return latitude longitude as 0
 * @param address
 */
export async function getCoordinatesFromTheAddress(
    address: string
): Promise<{ latitude: number; longitude: number; timeZone: string }> {
    let latitude = 0,
        longitude = 0,
        timeZone = '';
    const encodedAddress = encodeURI(address);
    const apiKey = 'AIzaSyADV3zLAzbiGiCay4yCvlXOXGDxX44ZTW4';
    // for getting time zone from coordinates we use https://timezonedb.com free service
    const timeZoneApiKey = '7M2AXJ9TLKLC';
    let res: any = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`
    );
    res = await res.json();
    if (res.results.length > 0) {
        latitude = res.results[0].geometry.location.lat;
        longitude = res.results[0].geometry.location.lng;
        await fetch(
            `https://api.timezonedb.com/v2.1/get-time-zone?key=${timeZoneApiKey}&format=json&by=position&lat=${latitude}&lng=${longitude}`
        )
            .then((result) => result.json())
            .then((parsed) => {
                timeZone = parsed?.zoneName;
            });
    }
    return {
        latitude,
        longitude,
        timeZone,
    };
}

export function generateQrCodeUrl(
    restaurantId: string,
    menuId: string = '',
    tableId: string | number = '',
    service: number,
    qrName: string = ''
) {
    let baseUrl = process.env.REACT_APP_WEBAPP_URL;
    if (!baseUrl) baseUrl = 'https://dev.gastrojames.ch/';
    return encodeURI(
        `${baseUrl}/restaurant/${restaurantId}/menu/${menuId}?tableId=${tableId}&service=${service}&qrName=${removeWhiteSpace(
            qrName
        )}`
    );
}

export function generateDynamicQrCodeUrl(
    variables: { key: string; value: string | undefined }[] = [
        { key: '@getMenuForTable', value: '609bd8b7f8252737ac608336' },
        { key: '@tableId', value: '111' },
    ]
) {
    let fullUrl: string =
        'https://jamesmobile.page.link/?link=https://jamesmobile&apn=com.jamesmobile&afl=https://jamesmobile&isi=1578854901&ibi=com.james.mobile&ifl=https://jamesmobile';
    const deepLinkUrl = 'https://jamesmobile&';

    const getActionEndString = (action: string) => {
        const actionLength = action.length;
        const actionMiddleIndex = actionLength / 2;
        const lastString = action.substring(actionLength - 1, actionLength);
        const middleString = action.substring(actionMiddleIndex, actionMiddleIndex + 1);
        const firstString = action.substring(0, 1);

        return lastString + middleString + firstString;
    };

    let renderedValues = '';
    variables.map(
        (variable) =>
            (renderedValues += `${variable.key}${variable.value}${getActionEndString(
                variable.key
            )}`)
    );

    const replaceValue = deepLinkUrl.slice(0, -1) + '/' + renderedValues + '&';
    fullUrl += '/' + renderedValues;
    fullUrl = fullUrl.split(deepLinkUrl).join(replaceValue);
    return fullUrl;
}

export const priceStyleHelper = (
    price: number | string | undefined,
    skipLeftSide: boolean = false
) => {
    if (price) {
        const converted = price.toString();
        let leftSide: string, rightSide: string;
        const dotIndex = converted.indexOf('.');
        if (dotIndex !== -1) {
            leftSide = converted.substr(0, dotIndex);
            rightSide = converted.substr(dotIndex + 1);
        } else {
            leftSide = converted;
            rightSide = '00';
        }

        return `${!skipLeftSide ? (leftSide.length === 1 ? '0' + leftSide : leftSide) : leftSide}.${
            rightSide.length === 1 ? rightSide + '0' : rightSide
        }`;
    } else {
        return '00.00';
    }
};

export const asyncTimeout = (miliseconds: number = 1000) =>
    new Promise<void>((resolve) =>
        setTimeout(() => {
            resolve();
        }, miliseconds)
    );

export function isFullPageCount(count: number) {
    let isFullPage = false;
    for (let page of pagingOptions) {
        if (count === page) {
            isFullPage = true;
            break;
        }
    }

    return isFullPage;
}

export function truncateString(phrase: string = '', length: number = 10) {
    let result = phrase;
    if (!!phrase && phrase.length > length) {
        result = `${phrase.slice(0, length - 3)}...`;
    }

    return result;
}

export function trimLeftAndRight(text: string | undefined) {
    if (!text) {
        return '';
    }
    text = text.trim();
    return text;
}

export function removeWhiteSpace(text: string | undefined) {
    if (!text) {
        return '';
    }
    return text.replaceAll('%20', ' ');
}

export function fillWhiteSpaces(text: string | undefined) {
    if (!text) {
        return text;
    }
    return text.replaceAll(' ', '%20');
}

export function formatNumber(number: number) {
    const thousand = 1000;
    const million = thousand * 1000;
    const billion = million * 1000;
    let renderedNumber: any = number;
    if (number >= thousand && number < million) {
        renderedNumber = (number / thousand).toFixed(2) + 'K';
    } else if (number >= million) {
        renderedNumber = (number / million).toFixed(2) + 'M';
    } else if (number >= billion) {
        renderedNumber = (number / billion).toFixed(2) + 'B';
    } else {
        renderedNumber = renderedNumber.toFixed(2);
    }
    return renderedNumber;
}

export function truncateLangString(text: string, maxLength = 20) {
    if (i18n.locale === 'de') {
        return truncateString(text, maxLength);
    }
    return text;
}

export function getRandomString(length = 6) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function getPositionName(position: Positions) {
    switch (position) {
        case Positions.COOK:
            return i18n.t('roles.Cook');
        case Positions.WAITER:
            return i18n.t('roles.Waiter');
        default:
            return position;
    }
}

export function getTaxName(tax: TaxType) {
    switch (tax) {
        case TaxType.StandardRate:
            return i18n.t('form.standardRate');
        case TaxType.ReducedRate:
            return i18n.t('form.reducedRate');
        case TaxType.SpecialRateForAccommodation:
            return i18n.t('form.specialRateForAccommodation');
        default:
            return 'Select';
    }
}

export function prepareLanguage(lang: string) {
    switch (lang) {
        case 'en':
            return 'en';
        case 'de':
            return 'de';
        default:
            return 'de';
    }
}

import React from 'react';

import i18n from 'i18n-js';
import clsx from 'clsx';

import { FormControl, Select, MenuItem } from '@material-ui/core';

import { OrderServices, TaxType } from 'constants/enums';
import { getServices } from 'constants/maps';
import { images } from 'assets';
import { TaxResponseModel } from 'api/agent';
import { useStyles } from '../styles';

interface ServiceTaxSelectProps {
    service: OrderServices;
    selectedTax: TaxType;
    onSelect: (tax: TaxType) => void;
    taxes: TaxResponseModel;
    styles?: {};
    disabled?: boolean;
    error?: string;
}

export default function ServiceTaxSelect(props: ServiceTaxSelectProps) {
    const classes = useStyles();
    const services = getServices();
    function getTaxRate(tax: TaxType) {
        switch (tax) {
            case TaxType.StandardRate:
                return props.taxes.standardRate + ' %';
            case TaxType.ReducedRate:
                return props.taxes.reducedRate + ' %';
            case TaxType.SpecialRateForAccommodation:
                return props.taxes.specialRateForAccommodation + ' %';
            default:
                return 'select';
        }
    }

    return (
        <>
            <FormControl fullWidth variant="filled">
                <Select
                    className={classes.serviceTaxRoot}
                    placeholder={'select'}
                    disabled={props.disabled}
                    MenuProps={{
                        classes: {
                            list: classes.menuList,
                        },
                    }}
                    label={!!props.error ? props.error : 'select'}
                    value={props.selectedTax}
                    error={!!props.error}
                    onChange={(e) => {
                        props.onSelect(e.target.value as TaxType);
                    }}
                    style={{ textAlign: 'start', ...props.styles }}
                    renderValue={() => (
                        <div className={classes.textContainer}>
                            {props.error && (
                                <label className={classes.selectTaxError} data-shrink="true">
                                    {props.error}
                                </label>
                            )}
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <img
                                    src={
                                        services.get(props.service)?.icon ??
                                        images.icons.chartVorort
                                    }
                                    alt="service"
                                />
                                <span style={{ marginLeft: 10 }}>
                                    {services.get(props.service)?.name}
                                </span>
                            </div>
                            <span
                                className={clsx({
                                    [classes.slectedRate]: props.selectedTax !== TaxType.None,
                                    [classes.selectedNone]: props.selectedTax === TaxType.None,
                                })}
                            >
                                {getTaxRate(props.selectedTax)}
                            </span>
                        </div>
                    )}
                >
                    <MenuItem value={TaxType.StandardRate} key={TaxType.StandardRate}>
                        {`${i18n.t('form.standardRate')} ${props.taxes.standardRate}%`}
                    </MenuItem>
                    <MenuItem value={TaxType.ReducedRate} key={TaxType.ReducedRate}>
                        {`${i18n.t('form.reducedRate')} ${props.taxes.reducedRate}%`}
                    </MenuItem>
                    <MenuItem
                        value={TaxType.SpecialRateForAccommodation}
                        key={TaxType.SpecialRateForAccommodation}
                    >
                        {`${i18n.t('form.specialRateForAccommodation')} ${
                            props.taxes.specialRateForAccommodation
                        }%`}
                    </MenuItem>
                </Select>
            </FormControl>
        </>
    );
}

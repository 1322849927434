import { Action, Reducer } from 'redux';

import { HourData } from 'components/restaurantInformation/components';
import { Positions } from 'constants/enums';
import { CategoryAction } from './actions';

export interface CreateCategoryModel {
    Name: string;
    Dishes: string[];
    openHours: HourData[];
    position: string;
}

export interface CategoryDishData {
    id: string;
    name: string;
    categoryId: string | null;
}

export interface CategoryDetails {
    name: string;
    id: string;
    isDisabled: boolean;
    position: Positions;
    openHours: HourData[];
}

export interface CategoryDetailsData {
    categories: CategoryDetails[];
    dishes: CategoryDishData[];
}
export interface CategoryState {
    categoryDetails: CategoryDetailsData | null;
}
const initialState: CategoryState = {
    categoryDetails: null,
};

export const reducer: Reducer<CategoryState> = (
    state: CategoryState | undefined = initialState,
    incomingAction: Action
): CategoryState => {
    const action = incomingAction as CategoryAction;

    switch (action.type) {
        case 'GET_CATEGORY_DETAILS_SUCCESS': {
            return {
                ...state,
                categoryDetails: action.data,
            };
        }
        case 'CREATE_CATEGORY_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories.push({
                id: action.id,
                name: action.name,
                openHours: action.openHours,
            });
            return {
                ...state,
                categoryDetails: newCategoryDetails,
            };
        }
        case 'DISABLE_OR_ENABLE_CATEGORIES_SUCCESS': {
            const newCategoryDetails: any = { ...state?.categoryDetails };
            newCategoryDetails.categories = newCategoryDetails.categories
                ? newCategoryDetails.categories.map((category: { id: string }) =>
                      action.result.categoriesIds.includes(category.id)
                          ? { ...category, isDisabled: action.result.isDisabled }
                          : category
                  )
                : newCategoryDetails.categories;
            return {
                ...state,
                categoryDetails: newCategoryDetails as CategoryDetailsData,
            };
        }
        default:
            return state;
    }
};

import React from 'react';
import { useDispatch } from 'react-redux';

import { Form, Formik } from 'formik';
import * as yup from 'yup';
import i18n from 'i18n-js';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import { DialogActions } from '@material-ui/core';

import { Input } from 'components/shared';
import * as MenuDetailsStore from 'store/menuDetailsStore';
import { trimLeftAndRight } from 'helpers/helperFunctions';

const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);

export interface RenameSectionProps {
    sectionId: string;
    sectionName: string;
    close: any;
    sections: string[];
}

interface RenameSectionModal {
    name: string;
}
export default function RenameSectionMenuItem(props: RenameSectionProps) {
    const dispatch = useDispatch();
    const [open, setOpen] = React.useState(false);
    const initalFormValues: RenameSectionModal = {
        name: props.sectionName,
    };

    const handleClickOpen = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(true);
        props.close();
    };

    const handleClose = (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setOpen(false);
    };

    const validationSchema = yup.object().shape({
        name: yup
            .string()
            .required(
                i18n.t('form.errors.required', {
                    name: 'Name',
                })
            )
            .test(
                'existsCheck',
                i18n.t('form.errors.alreadyTaken'),
                (value: any) => !props.sections.includes(value?.toUpperCase())
            ),
    });

    return (
        <>
            <StyledMenuItem color="primary" onClick={handleClickOpen}>
                <BorderColorIcon fontSize="small" color="disabled" style={{ marginRight: 23 }} />
                <ListItemText primary={i18n.t('common.renameSection')} />
            </StyledMenuItem>
            <Dialog
                open={open}
                onClick={(e) => e.stopPropagation()}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">{i18n.t('common.renameSection')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{i18n.t('common.enterNewSectionName')}</DialogContentText>
                    <Formik
                        initialValues={initalFormValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            return dispatch(
                                MenuDetailsStore.actionCreators.renameSection(props.sectionId, {
                                    name: trimLeftAndRight(values.name),
                                })
                            );
                        }}
                    >
                        {({ errors, submitForm }) => (
                            <Form>
                                <>
                                    <br />
                                    <Input
                                        error={errors.name}
                                        variant="filled"
                                        fullWidth={true}
                                        name={`name`}
                                        type="text"
                                        label={i18n.t('form.name')}
                                        placeholder={i18n.t('form.name')}
                                    />
                                    <br />
                                </>
                                <DialogActions>
                                    <Button type="button" color="primary" onClick={handleClose}>
                                        {i18n.t('button.cancel')}
                                    </Button>
                                    <Button
                                        onClick={submitForm}
                                        variant="contained"
                                        color="primary"
                                    >
                                        {i18n.t('button.rename')}
                                    </Button>
                                </DialogActions>
                            </Form>
                        )}
                    </Formik>
                </DialogContent>
            </Dialog>
        </>
    );
}

import backArrow from './icons/arrow-back.png';
import foodIcon from './icons/food-icon.png';
import homeDelivery from './icons/home-delivery.svg';
import homeDeliveryDisabled from './icons/home-delivery-disabled.svg';
import takeAway from './icons/take-away.svg';
import takeAwayDisabled from './icons/take-away-disabled.svg';
import hasOnSite from './icons/has-on-site.svg';
import hasOnSiteDisabled from './icons/has-on-site-disabled.svg';
import edit from './icons/edit.svg';
import doubleLeaf from './icons/double-leaf.svg';
import halal from './icons/halal.svg';
import pen from './icons/pen.svg';
import penDisabled from './icons/pen-disabled.svg';
import removeCircle from './icons/remove-circle.svg';
import lens from './icons/lens.svg';
import lensBlue from './icons/lens-blue.svg';
import chili from './icons/chili-paper.svg';
import leaf from './icons/green-leaf.svg';
import coffee from './icons/coffee-cup.svg';
import user from './icons/user.svg';
import marker from './icons/map-marker.svg';
import email from './icons/email.svg';
import phone from './icons/phone.svg';
import fileDownload from './icons/file-download.svg';
import fileDownloadDisabled from './icons/file-download-disabled.svg';
import plus from './icons/plus.svg';
import removeCircleDisabled from './icons/remove-circle-disabled.svg';
import search from './icons/search.svg';
import restaurantDefaultLogo from './icons/restaurant-logo.svg';
import calendar from './icons/calendar.svg';
import print from './icons/print.svg';
import blackPenguin from './icons/black-penguin.svg';
import list from './icons/list.svg';
import listSelected from './icons/list-selected.svg';
import graph from './icons/graph.svg';
import graphSelected from './icons/graph-selected.svg';
import chartHomeDelivery from './icons/chart-home-delivery.svg';
import chartTakeAway from './icons/chart-take-away.svg';
import chartVorort from './icons/chart-vorort.svg';
import rightArrow from './icons/right-arrow.svg';
import breakfastLogo from './icons/breakfast-logo.svg';

import logo from '../images/logo.svg';
import creditCard from '../images/credit-card.svg';
import brokenCup from '../images/broken-cup.svg';
import collapseIcon from './icons/collpase-icon.svg';
import users from './icons/users.svg';
import restaurants from './icons/restaurants.svg';
import finance from './icons/finance.svg';
import logoNoText from './icons/logo-no-text.svg';
import userAvatar from './icons/user-logo.svg';
import logout from './icons/logout.svg';
import penNavbar from './icons/pen-navbar.svg';
import menu from './icons/menu.svg';
import triangleDown from './icons/triangle-down.svg';
import threeDots from './icons/three-dots.svg';
import filter from './icons/filter.svg';
import filterList from './icons/filter-list.svg';
import circlePlus from './icons/circle-plus.svg';
import dollar from './icons/dollar.svg';
import checkedCircle from './icons/checked-circle.svg';
import triangleAtention from './icons/triangle-atention.svg';
import circlePlusColored from './icons/circle-plus-colored.svg';
import whiteWarning from './icons/whiteWarning.svg';
import warning from './icons/warning.svg';
import topArrow from './icons/top-arrow.svg';
import money from './icons/money.png';
import engFlag from './icons/eng-flag.svg';
import gerFlag from './icons/ger-flag.svg';
import penOnlyDisabled from './icons/pen-only-disabled.svg';
import checkMark from './icons/check-mark.svg';

import chef from './icons/chef.svg';
import barmen from './icons/barmen.svg';
import waiter from './icons/waiter.svg';
import manager from './icons/manager.svg';
import cancelled from './icons/cancelled.svg';
import product from './icons/product.svg';
import lock from './icons/lock.svg';
import arrowDropdown from './icons/arrow-dropdown.svg';
import ingredientsDefault from './ingredients-default.jpg';
import settings from './icons/settings.svg';
import cross from './icons/cross.svg';
import download from './icons/download.svg';
import settingsError from './icons/settings-error.svg';

const images = {
    logo,
    brokenCup,
    creditCard,
    ingredientsDefault,
    icons: {
        backArrow,
        foodIcon,
        homeDelivery,
        homeDeliveryDisabled,
        takeAway,
        takeAwayDisabled,
        hasOnSite,
        hasOnSiteDisabled,
        edit,
        doubleLeaf,
        halal,
        pen,
        penDisabled,
        removeCircle,
        removeCircleDisabled,
        lens,
        lensBlue,
        chili,
        leaf,
        coffee,
        user,
        marker,
        email,
        phone,
        fileDownload,
        fileDownloadDisabled,
        plus,
        search,
        restaurantDefaultLogo,
        calendar,
        print,
        blackPenguin,
        list,
        listSelected,
        graph,
        graphSelected,
        chartHomeDelivery,
        chartTakeAway,
        chartVorort,
        rightArrow,
        breakfastLogo,
        collapseIcon,
        users,
        restaurants,
        finance,
        logoNoText,
        userAvatar,
        logout,
        penNavbar,
        menu,
        triangleDown,
        threeDots,
        filter,
        filterList,
        circlePlus,
        dollar,
        checkedCircle,
        triangleAtention,
        circlePlusColored,
        whiteWarning,
        warning,
        topArrow,
        money,
        chef,
        barmen,
        waiter,
        manager,
        engFlag,
        gerFlag,
        cancelled,
        product,
        lock,
        arrowDropdown,
        penOnlyDisabled,
        checkMark,
        settings,
        cross,
        download,
        settingsError,
    },
};

export default images;

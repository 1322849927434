import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';

import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import { makeStyles, Theme, createStyles, alpha } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardActions, ListItemIcon } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { RemoveCircleOutline } from '@material-ui/icons';
import clsx from 'clsx';

import coffee from 'assets/coffee-cup.svg';
import { images } from 'assets';
import commonStyles from 'config/commonStyles';
import { ApplicationState } from 'store';
import { BasicThreeDotsMenu, Text, PopupConfirmation } from 'components/shared';
import { MenuType, OrderDishStatus, Roles } from 'constants/enums';
import DishManagementDialog from './DishManagementDialog';
import { OrderDetailsItem, actionCreators } from 'store/ordersStore';
import { priceStyleHelper } from 'helpers/helperFunctions';
import { IngredientModel, DishCreateModel } from 'api/agent';
import * as MenuDetailsStore from 'store/menuDetailsStore';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        'MuiCardHeader-root': {
            paddingBottom: '0px',
        },
        root: {
            margin: '15px 5px 33px 5px',
            width: 345,
            height: 185,
            '&:hover': {
                cursor: 'pointer',
            },
            boxShadow: `0px 4px 8px 0px ${alpha(theme.palette.common.black, 0.11)}`,
            border: '1px solid transparent',
        },
        selected: {
            backgroundColor: theme.palette.warning.light,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
        },
        spicesContainer: {
            height: 20,
            '& button': {
                marginLeft: '10px',
            },
            '& button:first-child': {
                marginLeft: 0,
            },
        },
        coverImg: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardPrice: {
            fontSize: '14px',
            color: theme.palette.primary.main,
            lineHeight: '16.5px',
        },
        cardTitleContainer: {
            height: 20,
            marginBottom: 20,
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
        },
        cardTitle: {
            fontWeight: 'bold',
            fontSize: '18px',
            marginRight: 10,
        },
        cardDesc: {
            fontSize: '14px',
            color: theme.palette.common.black,
        },
        cardContent: {
            height: '22%',
        },
        cardBottom: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        labelPrice: {
            fontSize: '20px',
            fontWeight: 900,
            lineHeight: '23.44px',
        },
        itemAmountContainer: {
            ...commonStyles.flexRowCenter,
            position: 'absolute',
            width: 345,
            right: 0,
            bottom: 16,
        },
        itemAmountComponent: {
            ...commonStyles.flexRowSpaceBetween,
            border: `1px solid ${theme.palette.primary.main}`,
            width: 55,
            height: 36,
            borderRadius: 20,
            backgroundColor: theme.palette.common.white,
            padding: '0 16px',
        },
        itemAmountAction: {
            color: theme.palette.secondary.main,
            fontWeight: 900,
            fontSize: 17,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        itemAmountCount: {
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: 15,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        forModal: {
            margin: '15px 5px 33px 10px',
        },
        smallText: {
            fontSize: 14,
        },
    })
);

interface DishPreviewItemProps {
    id: string;
    dish?: any;
    isModal?: boolean;
    isSelected?: boolean;
    isDailyMenuDish?: boolean;
    isDisabled?: boolean;
    setSelected?: (id: string) => void;
    dailyMenuId?: string;
    sectionId: string;
}

export default function DishPreviewItem(props: DishPreviewItemProps) {
    const { dish, dailyMenuId } = props;
    const classes = useStyles();
    const dispatch = useDispatch();
    const user = useSelector((state: ApplicationState) => state.user);
    const menu = useSelector((state: ApplicationState) => state.menuDetails.menu);
    const order = useSelector((state: ApplicationState) => state.orders.orderDetails);
    const allergens = useSelector((state: ApplicationState) => state.ingredients.allergies);

    const [showConfirmation, setShowConfirmation] = useState(0);
    const [showEditDialog, setShowEditDialog] = useState(false);

    const targetItems = order.orderItems.filter(
        (x) => x.id === dish.id && x.status !== OrderDishStatus.Ready
    );
    const targetItem = targetItems[0];

    function handleClick() {
        if (props.isModal && !!props.setSelected) {
            if (!props.isDisabled) {
                props.setSelected(dish.id);
                dispatch(actionCreators.setSelectedDish(dish.id, dailyMenuId));
            }
        } else {
            setShowEditDialog(true);
        }
    }

    const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
        setShowEditDialog(false);
        event?.stopPropagation();
    };

    function itemAmount() {
        return targetItem?.amount || 0;
    }

    function handleAmount(decrease: boolean = false) {
        const item: OrderDetailsItem = {
            price: props.dish.price,
            name: props.dish.name,
            id: props.dish.id,
            amount: 1,
            shortDescription: props.dish.shortDescription,
            orderId: order.id,
            isReady: targetItem?.isReady ?? false,
            orderNumber: order.orderNumber,
            comment: order.comment,
            service: order.service,
            allergens: props.dish.allergens,
            ingredients: props.dish.ingredients,
            status: targetItem?.status ?? OrderDishStatus.None,
            categoryId: '',
            categoryName: '',
            dailyMenuId: '',
            dishes: [],
        };
        return dispatch(actionCreators.addItemToOrder(item, decrease ? -1 : 1));
    }

    function deleteDish() {
        dispatch(
            MenuDetailsStore.actionCreators.deleteDish({
                dishId: props.id,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function disableDish() {
        dispatch(
            MenuDetailsStore.actionCreators.disableDish({
                dishId: props.id,
                sectionId: props.sectionId,
                menuId: menu.id,
            })
        );
    }

    function updateDish(model: DishCreateModel) {
        dispatch(MenuDetailsStore.actionCreators.updateItem(model, props.dish.id, props.sectionId));
    }

    const getThreeDotItems = () => {
        const items = [
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={() => setShowEditDialog(true)}
            >
                <ListItemIcon>
                    <EditIcon fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">{i18n.t('common.editItem')}</Typography>
            </div>,
            <div
                className={classes.threeDotsMenuItemContainer}
                onClick={(e) => {
                    setShowConfirmation(1);
                }}
            >
                <ListItemIcon>
                    <RemoveCircleOutline fontSize="small" color="disabled" />
                </ListItemIcon>
                <Typography variant="inherit">
                    {!dish.isDisabled ? i18n.t('common.disable') : i18n.t('common.enable')}
                </Typography>
            </div>,
        ];
        if (dish.isDisabled) {
            items.push(
                <div
                    className={classes.threeDotsMenuItemContainer}
                    onClick={() => setShowConfirmation(2)}
                >
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" color="disabled" />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n.t('common.deleteItem')}</Typography>
                </div>
            );
        }
        return items;
    };

    function prepareAmountCounter() {
        return !props.isDailyMenuDish && (itemAmount() > 0 || props.isSelected) ? (
            <div className={classes.itemAmountContainer}>
                <div className={classes.itemAmountComponent}>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(true)}>
                        -
                    </div>
                    <div className={classes.itemAmountCount}>{itemAmount()}</div>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(false)}>
                        +
                    </div>
                </div>
            </div>
        ) : null;
    }

    function getAllergenIcons(ingredient: IngredientModel) {
        return ingredient.allergens.map((id: string) => {
            const allergen = allergens.find((each) => each.id === id);
            return (
                <img
                    key={allergen?.id}
                    src={allergen?.icon}
                    alt={allergen?.name}
                    style={{ maxHeight: '100%', marginLeft: 7 }}
                />
            );
        });
    }

    return (
        <div style={{ position: 'relative' }}>
            <Card
                elevation={0}
                className={clsx(classes.root, {
                    [classes.selected]: props.isSelected,
                })}
                /*  className={clsx(classes.root, {
                    [classes.selected]: props.isSelected,
                    [classes.forModal]: props.isModal,
                })} */
                style={{ opacity: dish.isDisabled ? 0.5 : 1 }}
                onClick={handleClick}
            >
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <img
                                src={dish.photo ? dish.photo : coffee}
                                className={dish.photo ? classes.coverImg : ''}
                                alt="section_logo"
                            />
                        </Avatar>
                    }
                    action={
                        <>
                            {user.role === Roles.OWNER && (
                                <BasicThreeDotsMenu items={getThreeDotItems()} />
                            )}
                        </>
                    }
                    title={
                        <div className={classes.cardTitleContainer}>
                            <Text
                                text={dish.name}
                                className={classes.cardTitle}
                                fontSize={18}
                                maxLength={15}
                            />
                            <div className={classes.spicesContainer}>
                                {dish.isSpicy && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.chili} alt="chili-paper" />
                                    </IconButton>
                                )}
                                {dish.isVegetarian && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.leaf} alt="green-leaf" />
                                    </IconButton>
                                )}
                                {dish.isVegan && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.doubleLeaf} alt="chili-paper" />
                                    </IconButton>
                                )}
                                {dish.isHalal && (
                                    <IconButton aria-label="add to favorites">
                                        <img src={images.icons.halal} alt="green-leaf" />
                                    </IconButton>
                                )}
                            </div>
                        </div>
                    }
                />
                <PopupConfirmation
                    open={showConfirmation > 0}
                    close={() => setShowConfirmation(0)}
                    action={showConfirmation === 1 ? disableDish : deleteDish}
                    title={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('common.disable')
                                : i18n.t('common.enable')
                            : i18n.t('common.delete')
                    }
                    description={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('confirmation.disableItem')
                                : i18n.t('confirmation.enableItem')
                            : i18n.t('confirmation.deleteItem')
                    }
                    activeBtn={
                        showConfirmation === 1
                            ? !dish.isDisabled
                                ? i18n.t('common.disable')
                                : i18n.t('common.enable')
                            : i18n.t('common.delete')
                    }
                />
                <CardContent className={classes.cardContent}>
                    <Text text={dish.shortDescription} maxLength={150} />
                </CardContent>
                <CardActions disableSpacing className={classes.cardBottom}>
                    <div className={classes.spicesContainer}>{getAllergenIcons(dish)}</div>
                    <IconButton>
                        <div
                            className={classes.cardPrice}
                            style={{
                                visibility: menu.type !== MenuType.Daily ? 'visible' : 'hidden',
                            }}
                        >
                            CHF{' '}
                            <label
                                className={clsx(classes.labelPrice, {
                                    [classes.smallText]: dish.price.toString().length > 10,
                                })}
                            >
                                {priceStyleHelper(dish.price, true)}
                            </label>
                        </div>
                    </IconButton>
                </CardActions>
                <DishManagementDialog
                    dish={dish}
                    sections={menu.sections}
                    action={updateDish}
                    isOpen={showEditDialog}
                    onClose={handleClose}
                    disabled={user.role !== Roles.OWNER}
                />
            </Card>
            {props.isModal && prepareAmountCounter()}
        </div>
    );
}

import React from 'react';

import i18n from 'i18n-js';
import { Divider } from '@material-ui/core';

import { images } from 'assets';
import NavbarListItem from './NavbarListItem';
import NavbarSectionHeader from './NavbarSectionHeader';
import { NavbarList } from './NavbarList';
import NewOrdersCounter from './NewOrdersCounter';

interface RestaurantOwnerNavbarProps {
    isOpen: boolean;
}

export default function RestaurantOwnerNavbar(props: RestaurantOwnerNavbarProps) {
    return (
        <>
            <NavbarList isOpen={props.isOpen}>
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/orders"
                    icon={images.icons.penNavbar}
                    title={i18n.t('navigationTabs.orders')}
                />
                <NewOrdersCounter isOpen={props.isOpen} />
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/menu"
                    icon={images.icons.menu}
                    title={i18n.t('navigationTabs.menuManagement')}
                    matches={['/menu']}
                />
            </NavbarList>
            <Divider />
            <NavbarList isOpen={props.isOpen}>
                <NavbarSectionHeader
                    isOpen={props.isOpen}
                    title={i18n.t('navigationTabs.management')}
                />
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/users"
                    icon={images.icons.users}
                    title={i18n.t('navigationTabs.userManagement')}
                />
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/restaurantInformation"
                    icon={images.icons.restaurants}
                    title={i18n.t('navigationTabs.restaurantInformation')}
                />
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/ingredients"
                    icon={images.icons.product}
                    title={i18n.t('navigationTabs.products')}
                />
                <NavbarListItem
                    isOpen={props.isOpen}
                    path="/finance"
                    icon={images.icons.dollar}
                    title={i18n.t('navigationTabs.finance')}
                />
            </NavbarList>
        </>
    );
}

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import agent from 'api/agent';
import { GlobalAction } from './globalStore/actions';
import globalStore from './globalStore';

//STATE
export interface RolesState {
    roles: RoleData[];
}

export interface RoleData {
    id: string;
    name: string;
}

//ACTIONS
interface RolesGetStartAction {
    type: 'ROLES_GET_START';
}

interface RolesGetSuccessAction {
    type: 'ROLES_GET_SUCCESS';
    users: RoleData[];
}

interface RolesGetErrorAction {
    type: 'ROLES_GET_ERROR';
}

//ACTION TYPES
export type RolesAction =
    | RolesGetStartAction
    | RolesGetSuccessAction
    | RolesGetErrorAction
    | GlobalAction;

//ACTION CREATORS
export const actionCreators = {
    getRoles: (): AppThunkAction<RolesAction> => (dispatch) => {
        dispatch({
            type: 'ROLES_GET_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.Roles.GetAll()
            .then((response) => dispatch({ type: 'ROLES_GET_SUCCESS', users: response }))
            .catch((e) => {
                dispatch({ type: 'ROLES_GET_ERROR' });
                globalStore.actionCreators.showToaster('error', e);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
};

//REDUCER
const initialState: RolesState = {
    roles: [],
};

export const reducer: Reducer<RolesState> = (
    state: RolesState | undefined,
    incomingAction: Action
): RolesState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as RolesAction;
    switch (action.type) {
        case 'ROLES_GET_SUCCESS':
            return {
                ...state,
                roles: action.users,
            };
        default:
            return state;
    }
};

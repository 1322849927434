import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent from '../api/agent';
import { MenuCreateModel } from '../api/agent';
import { GlobalAction } from './globalStore/actions';
import globalStore from './globalStore';
//STATE
export interface MenusState {
    menus: MenuData[];
    dishList: any[];
}

export interface MenuData {
    edit: any;
    id: string;
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    price: number;
    photo: string;
    type: number;
    sections: string[];
    dishesCount: number;
    isEnabled: boolean;
    position: number;
}

//ACTIONS
interface MenuCreateStartAction {
    type: 'MENU_CREATE_START';
}

interface MenuCreateSuccessAction {
    type: 'MENU_CREATE_SUCCESS';
    menu: MenuData;
}

interface MenuCreateErrorAction {
    type: 'MENU_CREATE_ERROR';
}

interface MenuEditErrorAction {
    type: 'MENU_EDIT_ERROR';
}

interface MenuEditStartAction {
    type: 'MENU_EDIT_START';
}

interface MenuEditSuccessAction {
    type: 'MENU_EDIT_SUCCESS';
    menu: MenuData;
}

interface MenuGetListStartAction {
    type: 'MENUS_GET_LIST_START';
}

interface MenuGetListSuccessAction {
    type: 'MENUS_GET_LIST_SUCCESS';
    menus: MenuData[];
}

interface MenuGetListErrorAction {
    type: 'MENUS_GET_LIST_ERROR';
}

interface MenuDisableStartAction {
    type: 'MENU_DISABLE_START';
}

interface MenuDisableSuccessAction {
    type: 'MENU_DISABLE_SUCCESS';
    menu: MenuData;
}

interface MenuDisableErrorAction {
    type: 'MENU_DISABLE_ERROR';
}

interface MenuDeleteStart {
    type: 'MENU_DELETE_START';
}

interface MenuDeleteSuccess {
    type: 'MENU_DELETE_SUCCESS';
    menuId: string;
}

interface MenuDeleteError {
    type: 'MENU_DELETE_ERROR';
}

interface MenuGetDishListStart {
    type: 'MENU_GET_DISH_LIST_START';
}

interface MenuGetDishListSuccess {
    type: 'MENU_GET_DISH_LIST_SUCCESS';
    dishList: any[];
}

interface MenuGetDishListError {
    type: 'MENU_GET_DISH_LIST_ERROR';
}

interface MenuReordertStart {
    type: 'MENUS_REORDER_START';
}

interface MenuReorderSuccess {
    type: 'MENUS_REORDER_SUCCESS';
    menus: any[];
}

interface MenuReorderError {
    type: 'MENUS_REORDER_ERROR';
}

//ACTION TYPES
type MenusActions =
    | MenuCreateStartAction
    | MenuCreateSuccessAction
    | MenuCreateErrorAction
    | MenuEditStartAction
    | MenuEditSuccessAction
    | MenuEditErrorAction
    | MenuGetListStartAction
    | MenuGetListSuccessAction
    | MenuGetListErrorAction
    | MenuDisableStartAction
    | MenuDisableSuccessAction
    | MenuDisableErrorAction
    | MenuDeleteStart
    | MenuDeleteSuccess
    | MenuDeleteError
    | MenuGetDishListStart
    | MenuGetDishListSuccess
    | MenuGetDishListError
    | GlobalAction
    | MenuReordertStart
    | MenuReorderSuccess
    | MenuReorderError;

//ACTION CREATORS
export const actionCreators = {
    createMenu:
        (model: MenuCreateModel): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_CREATE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Create(model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_CREATE_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_CREATE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    editMenu:
        (model: MenuCreateModel, id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_EDIT_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Edit(model, id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_EDIT_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_EDIT_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    getMenus:
        (id?: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENUS_GET_LIST_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.GetMenusDetails(id)
                .then((response) => {
                    dispatch({
                        type: 'MENUS_GET_LIST_SUCCESS',
                        menus: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENUS_GET_LIST_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    getDishList: (): AppThunkAction<MenusActions> => (dispatch) => {
        dispatch({
            type: 'MENU_GET_DISH_LIST_START',
        });
        agent.Menus.GetDishList()
            .then((response) => {
                dispatch({
                    type: 'MENU_GET_DISH_LIST_SUCCESS',
                    dishList: response,
                });
            })
            .catch((e) => {
                dispatch({
                    type: 'MENU_GET_DISH_LIST_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },

    disableMenu:
        (id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_DISABLE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Disable(id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_DISABLE_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_DISABLE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteMenu:
        (id: string): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_DELETE_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.Delete(id)
                .then(() => {
                    dispatch({
                        type: 'MENU_DELETE_SUCCESS',
                        menuId: id,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_DELETE_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderMenus:
        (ids: string[]): AppThunkAction<MenusActions> =>
        (dispatch) => {
            dispatch({
                type: 'MENUS_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ReorderMenus(ids)
                .then((response) => {
                    dispatch({
                        type: 'MENUS_REORDER_SUCCESS',
                        menus: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENUS_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};

//REDUCER
const initialState: MenusState = {
    menus: [],
    dishList: [],
};

export const reducer: Reducer<MenusState> = (
    state: MenusState | undefined,
    incomingAction: Action
): MenusState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as MenusActions;
    switch (action.type) {
        case 'MENU_CREATE_SUCCESS':
            let targetIndex = state.menus.findIndex((x) => x.position > action.menu.position);
            if (targetIndex === -1) targetIndex = state.menus.length;
            let updatedMenusState = [...state.menus];
            updatedMenusState.splice(targetIndex, 0, action.menu);

            return {
                ...state,
                menus: updatedMenusState,
            };
        case 'MENU_EDIT_SUCCESS':
            let previousMenusState = [...state.menus];
            const orderBeforeUpdate = previousMenusState.find((x) => x.id === action.menu.id);
            let newMenusState;
            if (orderBeforeUpdate?.position !== action.menu.position) {
                newMenusState = previousMenusState.filter((x) => x.id !== action.menu.id);
                const nextPositionIndex = newMenusState.findIndex(
                    (x) => x.position > action.menu.position
                );
                newMenusState.splice(
                    nextPositionIndex !== -1 ? nextPositionIndex : newMenusState.length,
                    0,
                    action.menu
                );
            } else {
                newMenusState = previousMenusState.map((x) =>
                    x.id === action.menu.id ? { ...action.menu, dishesCount: x.dishesCount } : x
                );
            }

            return {
                ...state,
                menus: newMenusState,
            };
        case 'MENUS_GET_LIST_SUCCESS':
            return {
                ...state,
                menus: action.menus ? action.menus : [],
            };
        case 'MENU_DISABLE_SUCCESS':
            return {
                ...state,
                menus: state.menus.map((x) =>
                    x.id === action.menu.id ? { ...x, isEnabled: action.menu.isEnabled } : x
                ),
            };
        case 'MENU_GET_DISH_LIST_SUCCESS': {
            return {
                ...state,
                dishList: action.dishList,
            };
        }
        case 'MENU_DELETE_SUCCESS': {
            const { menuId } = action;
            const newMenus = state.menus.filter((eachMenu) => eachMenu.id !== menuId);
            return {
                ...state,
                menus: newMenus,
            };
        }
        case 'MENUS_REORDER_SUCCESS': {
            const menusCopy = [...state.menus];
            menusCopy.sort((a, b) => action.menus.indexOf(a.id) - action.menus.indexOf(b.id));
            return {
                ...state,
                menus: menusCopy,
            };
        }

        default:
            return state;
    }
};

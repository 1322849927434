import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { FieldArray, Form, Formik } from 'formik';
import i18n from 'i18n-js';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { Typography, makeStyles, DialogActions, Tooltip } from '@material-ui/core';
import { AxiosResponse } from 'axios';

import { CuisineData } from 'store/restaurantDetailsStore';
import agent, { RestaurantTypesManagementModel } from 'api/agent';
import { images } from 'assets';
import { Input } from 'components/shared';
import { theme } from 'config/theme';
import globalStore from 'store/globalStore';
import { helperFunctions } from '../../helpers';
import { truncateString } from '../../helpers/helperFunctions';

interface RestaurantsCusinesModalProps {
    isOpen: boolean;
    onClose: () => void;
}

const useStyles = makeStyles({
    input: {
        position: 'relative',
        '& .MuiInputLabel-formControl': {
            top: -7,
        },
        '& img': {
            position: 'absolute',
            top: '18px',
            right: '18px',
            cursor: 'pointer',
        },
        '& img.disabled-img': {
            cursor: 'auto',
        },
    },
    errorMessage: {
        position: 'absolute',
        left: 10,
        top: 5,
        color: theme.palette.error.dark,
        fontSize: 12,
        zIndex: 1,
    },
    addButton: {
        width: 136,
        position: 'absolute',
        top: 15,
        right: 25,
    },
    removeCircle: {
        height: 20,
        width: 20,
    },
});

export function RestaurantsCuisinesModal(props: RestaurantsCusinesModalProps) {
    const classes = useStyles();
    const [cuisines, setCuisines] = useState<CuisineData[]>([]);
    const [formHasChanged, setFormHasChanged] = useState(false);
    const dispatch = useDispatch();
    const getCuisines = async () => {
        dispatch(globalStore.actionCreators.showSpiner());
        const data: AxiosResponse<CuisineData[]> = await agent.Restaurants.GetCuisineTypes();
        setCuisines(data.data);
        dispatch(globalStore.actionCreators.hideSpiner());
    };

    useEffect(() => {
        getCuisines();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [showCuisineError, setShowCuisineError] = useState<string[]>([]);
    function handleCuisineClick(cuisine: CuisineData) {
        if (cuisine.inUse > 0 && !showCuisineError.includes(cuisine.id)) {
            setShowCuisineError([...showCuisineError, cuisine.id]);
        }
    }
    return (
        <Dialog open={props.isOpen} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <Typography variant="h6">{i18n.t('restaurant.typesOfCuisine')}</Typography>
            </DialogTitle>
            <DialogContent>
                {cuisines.length === 0 && (
                    <DialogContentText>{i18n.t('nothingToSee.nothingToSee')}</DialogContentText>
                )}
                <Formik
                    initialValues={{ cuisines: cuisines }}
                    enableReinitialize
                    onSubmit={(values, actions) => {
                        const hasNotNamedCuisine = values.cuisines.find(
                            (cuisine) => cuisine.name.length === 0
                        );
                        if (hasNotNamedCuisine) {
                            dispatch(
                                globalStore.actionCreators.showToaster(
                                    'error',
                                    i18n.t('warnings.fillAllCuisineNames')
                                )
                            );
                            return;
                        }
                        let requestModel: RestaurantTypesManagementModel = {
                            create: [],
                            delete: [],
                            rename: [],
                        };
                        const toCreate = values.cuisines.filter((x) => !x.id);
                        if (toCreate.length > 0) {
                            requestModel.create = toCreate.map((x) => x.name);
                        }
                        const valuesIds = values.cuisines.map((x) => x.id);
                        cuisines.forEach(
                            (x) => !valuesIds.includes(x.id) && requestModel.delete.push(x.id)
                        );
                        let isValid = true;
                        values.cuisines.forEach((x) => {
                            if (x.inUse === 0 && !!x.id) {
                                const previousCuisine = cuisines.filter((y) => y.id === x.id);
                                if (previousCuisine[0]?.name !== x.name) {
                                    requestModel.rename.push({ id: x.id, name: x.name });
                                }
                            }
                            const isNumber = /^\d+$/.test(x.name);
                            if (isNumber) {
                                isValid = false;
                            }
                        });

                        if (!isValid) {
                            dispatch(
                                globalStore.actionCreators.showToaster(
                                    'error',
                                    i18n.t('warnings.cuisineNameNumber')
                                )
                            );
                            return;
                        }

                        dispatch(globalStore.actionCreators.showSpiner());
                        agent.Restaurants.ManageCuisineTypes(requestModel)
                            .then((response) => {
                                setCuisines(response);
                                actions.setValues({ cuisines: cuisines });
                                setFormHasChanged(false);
                            })
                            .catch((e) => {
                                dispatch(globalStore.actionCreators.showToaster('error', e));
                            })
                            .finally(() => dispatch(globalStore.actionCreators.hideSpiner()));
                    }}
                >
                    {({ values, submitForm }) => (
                        <Form onChange={() => setFormHasChanged(true)}>
                            <FieldArray name="cuisines">
                                {({ remove, unshift }) => (
                                    <div>
                                        {values.cuisines.length > 0 &&
                                            values.cuisines.map((cuisine, index) => (
                                                <div className={classes.input}>
                                                    {showCuisineError.includes(cuisine.id) && (
                                                        <span className={classes.errorMessage}>
                                                            {i18n.t('warnings.canNotEditType', {
                                                                number: cuisine.inUse,
                                                                postfix:
                                                                    cuisine.inUse > 1 ? 's' : '',
                                                            })}
                                                        </span>
                                                    )}
                                                    <Tooltip
                                                        disableHoverListener={
                                                            cuisine.name.length < 50
                                                        }
                                                        title={cuisine.name}
                                                    >
                                                        <Input
                                                            onClick={() =>
                                                                handleCuisineClick(cuisine)
                                                            }
                                                            id={index}
                                                            error={showCuisineError.includes(
                                                                cuisine.id
                                                            )}
                                                            variant="filled"
                                                            fullWidth={true}
                                                            name={`cuisines.${index}.name`}
                                                            type="text"
                                                            value={truncateString(cuisine.name, 50)}
                                                            placeholder={
                                                                cuisine.name
                                                                    ? `${cuisine.name} cuisine`
                                                                    : 'New cuisine'
                                                            }
                                                            disabled={cuisine.inUse > 0}
                                                        />
                                                    </Tooltip>
                                                    {cuisine.inUse > 0 ? (
                                                        <img
                                                            src={images.icons.penDisabled}
                                                            alt="edit-icon"
                                                            className={'disabled-img'}
                                                        />
                                                    ) : (
                                                        <img
                                                            alt="remove-icon"
                                                            src={images.icons.removeCircle}
                                                            className={classes.removeCircle}
                                                            onClick={() => {
                                                                remove(index);
                                                                setFormHasChanged(true);
                                                            }}
                                                        />
                                                    )}
                                                </div>
                                            ))}
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => unshift({ id: '', name: '', inUse: 0 })}
                                            className={classes.addButton}
                                        >
                                            {helperFunctions.truncateLangString(
                                                i18n.t('button.addNew'),
                                                12
                                            )}
                                        </Button>
                                    </div>
                                )}
                            </FieldArray>
                            <DialogActions>
                                <Button type="button" color="primary" onClick={props.onClose}>
                                    {i18n.t('button.cancel')}
                                </Button>
                                <Button
                                    onClick={submitForm}
                                    variant="contained"
                                    color="primary"
                                    disabled={!formHasChanged}
                                >
                                    {i18n.t('common.save')}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
}

import React from 'react';

import { Dialog } from '@material-ui/core';
import { MenusPage } from '../MenusPage';
import { OrderUpdateModel } from 'api/agent';
import { useStyles } from './styles';

export default function MenusModal(props: {
    isOpen: boolean;
    onClose: () => void;
    saveChanges: (model: OrderUpdateModel | null) => void;
}) {
    const classes = useStyles();

    return (
        <Dialog
            maxWidth={'lg'}
            open={props.isOpen}
            aria-labelledby="form-dialog-title"
            className={classes.menusModalRoot}
        >
            <MenusPage isModal={true} onClose={props.onClose} saveChanges={props.saveChanges} />
        </Dialog>
    );
}

import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import agent from 'api/agent';
import { RestaurantData } from './restaurantsStore';
import axios from 'axios';
import { history } from 'index';
import { GlobalAction } from './globalStore/actions';
import globalStore from './globalStore';

//STATE
export interface UserState {
    role: string;
    authUserId: string;
    restaurants: RestaurantData[];
    currentRestaurantId: string;
    browserData: any;
    profile: UserProfile;
    refreshHome: boolean;
}

interface UserProfile {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    photo: string;
    addressLine: string;
    zip: string;
    city: string;
}

export interface UserRestaurantsData {
    currentRestaurantId: string;
    restaurants: RestaurantData[];
}

export interface UserPositionAndCategory {
    restaurantId: string;
    currentPosition: string;
    currentCategory: string;
}

const KEY = 'userData';
export function loadUserRestaurants() {
    try {
        const serializedState = localStorage.getItem(KEY);
        if (!serializedState) return undefined;
        return JSON.parse(serializedState) as RestaurantData[];
    } catch (e) {
        return undefined;
    }
}

export function removeUserData() {
    localStorage.removeItem(KEY);
}

function saveUserRestaurants(data: RestaurantData[]) {
    try {
        const serializedState = JSON.stringify(data);
        localStorage.setItem(KEY, serializedState);
    } catch (e) {
        console.warn(e);
        return undefined;
    }
}

//ACTIONS
interface UserSetRoleAction {
    type: 'USER_SET_ROLE';
    role: string;
}

interface UserGetDataAction {
    type: 'USER_GET_DATA';
}

interface UserGetRestaurantsStartAction {
    type: 'USER_GET_RESTAURANTS_START';
}

interface UserGetRestaurantsSuccessAction {
    type: 'USER_GET_RESTAURANTS_SUCCESS';
    restaurants: UserRestaurantsData;
}

interface UserGetRestaurantsErrorAction {
    type: 'USER_GET_RESTAURANTS_ERROR';
}

interface UserSetCurrentRestaurantStartAction {
    type: 'USER_SET_CURRENT_RESTAURANT_START';
}

interface UserSetCurrentRestaurantSuccessAction {
    type: 'USER_SET_CURRENT_RESTAURANT_SUCCESS';
    restaurantId: string;
}

interface UserSetCurrentRestaurantErrorAction {
    type: 'USER_SET_CURRENT_RESTAURANT_ERROR';
}

interface GetBrowserData {
    type: 'GET_BROWSER_DATA';
}

interface GetBrowserDataSuccess {
    type: 'GET_BROWSER_DATA_SUCCESS';
    data: any;
}

interface GetBrowserDataError {
    type: 'GET_BROWSER_DATA_ERROR';
}

interface SetAuthUserId {
    type: 'SET_AUTH_USER_ID';
    data: string;
}

interface UserGetProfileStartAction {
    type: 'USER_GET_PROFILE_START';
}

interface UserGetProfileSuccessAction {
    type: 'USER_GET_PROFILE_SUCCESS';
    profile: UserProfile;
}

interface UserGetProfileErrorAction {
    type: 'USER_GET_PROFILE_ERROR';
}

interface UserSetCurrentPositionAction {
    type: 'USER_SET_CURRENT_POSITION';
    position: string;
}

interface UserSetCategoryAction {
    type: 'USER_SET_CATEGORY';
    category?: string;
}

interface UserSetPositiosnAndCategoriesAction {
    type: 'USER_SET_POSITIONS_AND_CATEGORIES';
    data: RestaurantData[];
}

//ACTION TYPES
type UserAction =
    | UserSetRoleAction
    | UserGetDataAction
    | UserGetRestaurantsStartAction
    | UserGetRestaurantsSuccessAction
    | UserGetRestaurantsErrorAction
    | UserSetCurrentRestaurantStartAction
    | UserSetCurrentRestaurantSuccessAction
    | UserSetCurrentRestaurantErrorAction
    | GetBrowserData
    | GetBrowserDataSuccess
    | GetBrowserDataError
    | SetAuthUserId
    | UserGetProfileStartAction
    | UserGetProfileSuccessAction
    | UserGetProfileErrorAction
    | GlobalAction
    | UserSetCurrentPositionAction
    | UserSetCategoryAction
    | UserSetPositiosnAndCategoriesAction;

//ACTION CREATORS
export const actionCreators = {
    userSetRole:
        (role: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_ROLE',
                role: role,
            });
        },
    getUserRestaurants: (): AppThunkAction<UserAction> => (dispatch) => {
        dispatch({
            type: 'USER_GET_RESTAURANTS_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.User.GetUserRestaurants()
            .then((response) =>
                dispatch({
                    type: 'USER_GET_RESTAURANTS_SUCCESS',
                    restaurants: response,
                })
            )
            .catch((e) => {
                dispatch({
                    type: 'USER_GET_RESTAURANTS_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e)(dispatch);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    setUserCurrentRestaurant:
        (id: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CURRENT_RESTAURANT_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.User.SetUserCurrentRestaurant(id)
                .then((response) => {
                    dispatch({
                        type: 'USER_SET_CURRENT_RESTAURANT_SUCCESS',
                        restaurantId: response,
                    });
                    if (window.location.pathname.includes('menu/')) {
                        history.push('/menu');
                    }
                    window.location.reload();
                })
                .catch((e) => {
                    dispatch({
                        type: 'USER_SET_CURRENT_RESTAURANT_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    getBrowserData: (): AppThunkAction<UserAction> => (dispatch) => {
        axios
            .get('https://ipapi.co/json', {
                headers: {
                    'Content-Type': 'application/json',
                    // 'Content-Type': 'application/x-www-form-urlencoded',
                },
            })
            .then((res) => {
                dispatch({
                    type: 'GET_BROWSER_DATA_SUCCESS',
                    data: res.data,
                });
            })
            .catch(() => {
                dispatch({
                    type: 'GET_BROWSER_DATA_ERROR',
                });
            });
    },
    getUserProfile: (): AppThunkAction<UserAction> => (dispatch) => {
        dispatch({
            type: 'USER_GET_PROFILE_START',
        });
        globalStore.actionCreators.showSpiner()(dispatch);
        agent.User.GetUserProfile()
            .then((response) =>
                dispatch({
                    type: 'USER_GET_PROFILE_SUCCESS',
                    profile: response,
                })
            )
            .catch((e) => {
                dispatch({
                    type: 'USER_GET_PROFILE_ERROR',
                });
                globalStore.actionCreators.showToaster('error', e);
            })
            .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
    },
    setCurrentPosition:
        (position: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CURRENT_POSITION',
                position: position,
            });
        },
    setCategory:
        (category?: string): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_CATEGORY',
                category: category,
            });
        },
    setUserPositiosnAndCategories:
        (data: RestaurantData[]): AppThunkAction<UserAction> =>
        (dispatch) => {
            dispatch({
                type: 'USER_SET_POSITIONS_AND_CATEGORIES',
                data: data,
            });
        },
};

//REDUCER
const initialState: UserState = {
    role: '',
    authUserId: '',
    restaurants: [],
    currentRestaurantId: '',
    browserData: null,
    profile: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        photo: '',
        addressLine: '',
        zip: '',
        city: '',
    },
    refreshHome: false,
};

export const reducer: Reducer<UserState> = (
    state: UserState | undefined,
    incomingAction: Action
): UserState => {
    if (state === undefined) {
        const savedRestaurants = loadUserRestaurants();
        return savedRestaurants ? { ...initialState, restaurants: savedRestaurants } : initialState;
    }

    const action = incomingAction as UserAction;
    switch (action.type) {
        case 'USER_SET_ROLE':
            return {
                ...state,
                role: action.role,
            };
        case 'USER_GET_RESTAURANTS_SUCCESS':
            const currentRestaurant = state.restaurants.find(
                (x) => x.id === action.restaurants.currentRestaurantId
            );
            if (currentRestaurant) {
                if (!currentRestaurant.currentPosition) {
                    if (currentRestaurant.positions && currentRestaurant?.positions.length > 0) {
                        currentRestaurant.currentPosition = currentRestaurant?.positions[0];
                    }
                }
            }

            let newRestaurants = [];
            for (let restaurant of action.restaurants.restaurants) {
                const existsRestaurant = state.restaurants.find((x) => x.id === restaurant.id);
                if (existsRestaurant) {
                    newRestaurants.push({
                        ...restaurant,
                        currentPosition: existsRestaurant.currentPosition,
                        currentCategory: existsRestaurant.currentCategory,
                    });
                } else {
                    newRestaurants.push(restaurant);
                }
            }

            /* action.restaurants.restaurants.map((res) =>
                res.id !== action.restaurants.currentRestaurantId
                    ? { ...res, currentPosition: undefined }
                    : { ...res, currentPosition: currentPosition }
            ); */
            return {
                ...state,
                restaurants: newRestaurants,
                currentRestaurantId: action.restaurants.currentRestaurantId,
            };
        case 'USER_SET_CURRENT_RESTAURANT_SUCCESS':
            const newState = {
                ...state,
                currentRestaurantId: action.restaurantId,
            };
            return newState;
        case 'GET_BROWSER_DATA_SUCCESS': {
            return {
                ...state,
                browserData: action.data,
            };
        }
        case 'SET_AUTH_USER_ID': {
            return {
                ...state,
                authUserId: action.data,
            };
        }
        case 'USER_GET_PROFILE_SUCCESS':
            return {
                ...state,
                profile: action.profile,
            };
        case 'USER_SET_CURRENT_POSITION':
            const updatedRestaurants = state.restaurants.map(
                (res) =>
                    ({
                        ...res,
                        currentPosition:
                            res.id !== state.currentRestaurantId
                                ? res.currentPosition
                                : action.position,
                    } as RestaurantData)
            );
            const updatedState = {
                ...state,
                restaurants: updatedRestaurants,
            };
            saveUserRestaurants(updatedState.restaurants);
            return updatedState;
        case 'USER_SET_CATEGORY':
            const restaurant = state.restaurants.find(
                (res) => res.id === state.currentRestaurantId
            );
            if (restaurant) {
                restaurant.currentCategory = action.category;
                const renewedState = {
                    ...state,
                    restaurants: state.restaurants.map((res) =>
                        res.id === restaurant.id ? restaurant : res
                    ),
                };
                saveUserRestaurants(renewedState.restaurants);
                return renewedState;
            }

            return state;
        case 'USER_SET_POSITIONS_AND_CATEGORIES':
            let updatedRestaurantsData = [];
            for (let restaurant of state.restaurants) {
                const newData = action.data.find((x) => x.id === restaurant.id);
                if (newData) {
                    updatedRestaurantsData.push({
                        ...restaurant,
                        currentPosition: newData.currentPosition,
                        currentCategory: newData.currentCategory,
                    });
                } else {
                    updatedRestaurantsData.push(restaurant);
                }
            }
            return {
                ...state,
                restaurants: updatedRestaurantsData,
            };
        default:
            return state;
    }
};

import { createStyles, makeStyles, Theme, alpha } from '@material-ui/core';
import commonStyles from 'config/commonStyles';
import { red } from '@material-ui/core/colors';

export const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...commonStyles,
        header: {
            width: '96%',
            height: 60,
            borderBottom: '1px solid  #E5E5E5',
            padding: '0 24px',
            fontSize: 20,
            fontWeight: 500,
        },
        actionsContainer: {
            '& img:first-child': {
                height: 20,
                //margin: '5px 25px 0 0',
                cursor: 'pointer',
            },
            '& .MuiTextField-root': {
                marginLeft: 20,
            },
        },
        lensIcon: {
            marginLeft: 20,
            cursor: 'pointer',
        },
        searchInput: {
            borderBottom: 'none',
            '& div:hover, & div::after, & div::before': {
                borderBottom: 'none !important',
            },
        },
        iconButton: {
            color: theme.palette.common.black,
            marginRight: '10px',
        },
        addMenuRoot: {
            '& img': {
                width: 'auto',
                maxHeight: 171,
                height: 'auto',
            },
        },
        field: {
            backgroundColor: alpha(theme.palette.common.black, 0.09),
            width: '225px',
            '& label': {
                paddingLeft: '15px',
                paddingTop: '5px',
            },
            '& div': {
                paddingLeft: '10px',
            },
        },
        photoContainer: {
            width: '100%',
            height: '178px',
        },
        changePhotoButton: {
            left: '12%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.primary.main,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        removePhotoButton: {
            left: '55%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: alpha(theme.palette.common.white, 0.8),
            color: theme.palette.error.light,
            width: '155px',
            height: '36px',
            borderRadius: '4px',
            fontWeight: '500',
            letterSpacing: '1.25px',
            cursor: 'pointer',
        },
        dailyPositionTop: {
            position: 'absolute',
            top: '25%',
        },
        standartPositionTop: {
            position: 'absolute',
            top: '29%',
        },
        dialog: {
            '& form': {
                textAlign: 'end',
            },

            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                ...commonStyles.flexRowSpaceBetween,
                width: '100%',
            },
        },
        dropdown: {
            '& .MuiPopover-paper ': {
                top: '63px !important',
            },
            '& ul > li:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
            '& .MuiMenuItem-root': {
                minHeight: '48px !important',
            },
        },
        createNewDailyMenu: {
            fontSize: '20px',
            lineHeight: '30px',
        },
        'MuiCardHeader-root': {
            paddingBottom: '0px',
        },
        dishCardRoot: {
            margin: '0 0 25px 25px',
            filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));',
            width: 345,
            '&:hover': {
                cursor: 'pointer',
            },
        },
        spicesContainer: {
            '& button:first-child': {
                marginLeft: 0,
            },
            '& button': {
                marginLeft: '10px',
            },
        },
        coverImg: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
        },
        cardPrice: {
            fontSize: '14px',
            color: theme.palette.primary.main,
            lineHeight: '16.5px',
        },
        cardTitle: {
            fontWeight: 'bold',
            fontSize: '18px',
        },
        cardDesc: {
            fontSize: '14px',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        cardBottom: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        labelPrice: {
            fontSize: '20px',
            fontWeight: 900,
            lineHeight: '23.44px',
        },
        highlighter: {
            fontWeight: 'bolder',
            backgroundColor: 'transparent',
        },
        itemAmountContainer: {
            ...commonStyles.flexRowCenter,
            position: 'absolute',
            width: 345,
            right: 0,
            bottom: 16,
        },
        itemAmountComponent: {
            ...commonStyles.flexRowSpaceBetween,
            border: `1px solid ${theme.palette.primary.main}`,
            width: 55,
            height: 36,
            borderRadius: 20,
            backgroundColor: theme.palette.common.white,
            padding: '0 16px',
        },
        itemAmountAction: {
            color: theme.palette.secondary.main,
            fontWeight: 900,
            fontSize: 17,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        itemAmountCount: {
            color: theme.palette.common.black,
            fontWeight: 900,
            fontSize: 15,
            cursor: 'pointer',
            lineHeight: '38px',
        },
        editMenuRoot: {
            width: '100%',
            textAlign: 'end',
            '& img': {
                width: 'auto',
                maxHeight: 171,
                height: 'auto',
            },
            '& .MuiTextField-root': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            '& button': {
                marginLeft: 10,
            },
        },
        availabilityBox: {
            display: 'flex',
            justifyContent: 'space-between',
            '& .MuiFormControl-root:first-child': {
                borderRadius: '5px 0 0 0',
            },
            '& .MuiFormControl-root:last-child': {
                borderRadius: '0 5px 0 0',
            },
        },
        menuPreviewItemRoot: {
            filter: 'drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.08));',
            width: 350,
            height: 158,
            margin: '0 0 15px 10px;',
            cursor: 'pointer',
            '& .MuiCardContent-root': {
                padding: '0 0 0 8px;',
            },
            '& .MuiCardContent-root:last-child': {
                padding: '0 5px 0 16px',
            },
            '& .MuiCardActions-root': {
                justifyContent: 'space-between',
                marginTop: 50,
            },
            '& .MuiCardHeader-action': {
                marginTop: 3,
            },
            // FIX FOR SAFARI
            // https://github.com/mdn/browser-compat-data/issues/17726#issuecomment-1286324855
            transform: 'translateZ(0)',
        },
        imageBased: {
            color: theme.palette.common.white,
            backgroundPosition: 'center !important',
            backgroundSize: 'cover !important',
            backgroundRepeat: 'no-repeat !important',
            '& span, p': {
                color: theme.palette.common.white,
            },
        },
        media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
        },
        expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
                duration: theme.transitions.duration.shortest,
            }),
        },
        expandOpen: {
            transform: 'rotate(180deg)',
        },
        avatar: {
            backgroundColor: red[500],
        },
        disabled: {
            opacity: 0.5,
        },
        /* menuPreviewdialog: {
            '& form': {
                width: 468,
                textAlign: 'end',
            },

            '& .MuiTextField-root, button': {
                marginBottom: 28,
            },
            '& .MuiDialogTitle-root > h2': {
                ...commonStyles.flexRowSpaceBetween,
            },
        }, */
        /* dropdown: {
            '& .MuiPopover-paper ': {
                top: '56px !important',
            },
        }, */
        whiteThreeDots: {
            '& .MuiSvgIcon-root': {
                color: `${theme.palette.common.white} !important`,
            },
        },
        menusTableRoot: {
            display: 'inline-flex',
            flexWrap: 'wrap',
            //margin: '0 0 0 15px',
            //width: 'calc(100% + 32px)',
            padding: '23px 0 0 12px',
            maxHeight: 'calc(100% - 80px)',
            overflowY: 'auto',
        },
        forModal: {
            overflowX: 'hidden',
            height: 'calc(100% - 90px)',
            minWidth: 'calc(100% - 12px)',
        },
        searchResultRoot: {
            '& .MuiAccordionSummary-root': {
                height: '37px !important',
                minHeight: 0,
                backgroundColor: theme.palette.grey['300'],
            },
            '& .MuiListItem-gutters': {
                padding: '0',
            },
            '& .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters': {
                padding: '0 16px',
            },
            '& .MuiAccordionSummary-content.Mui-expanded': {
                justifyContent: 'center',
            },
            '& .MuiAccordion-root.MuiPaper-elevation1': {
                filter: 'none',
            },
            overflowX: 'hidden',
        },
        table: {
            minWidth: 650,
        },
        detailsList: {
            width: 'calc(100% + 32px)',
            margin: '0 0 0 -32px',
            display: 'inline-flex',
            flexWrap: 'wrap',
        },
        dots: {
            '&:hover': {
                cursor: 'pointer',
            },
        },
        breakfastLogo: {
            marginLeft: '15px',
            marginRight: '15px',
        },
        menuLabel: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            color: theme.palette.common.black,
            marginRight: '10px',
            minWidth: '438px',
            height: '41px',
            background: theme.palette.secondary.light,
            fontWeight: 'bold',
            fontSize: '16px',
            borderRadius: '5px',
        },
        menuPrice: {
            fontWeight: 900,
            fontSize: '16px',
            lineHeight: '19px',
            color: theme.palette.primary.main,
        },
        toolbarTitle: {
            flex: '1 1 90%',
        },
        nothingToSee: {
            width: '100%',
            height: '100%',
            textAlign: 'center',
            margin: '50px 0',
            '& h2': {
                color: theme.palette.secondary.dark,
            },
        },
        paper: {
            background: theme.palette.common.white,
            width: '100%',
        },
    })
);

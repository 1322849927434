import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import Highlighter from 'react-highlight-words';
import Avatar from '@material-ui/core/Avatar';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import { CardActions } from '@material-ui/core';
import Menu, { MenuProps } from '@material-ui/core/Menu';
import CardHeader from '@material-ui/core/CardHeader';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CardContent from '@material-ui/core/CardContent';

import images from 'assets/images';
import { PopupConfirmation } from 'components/shared';
import {
    DeleteMenuItem,
    DishManagementDialog,
} from 'components/menu/MenuSectionManagement/components';
import { DishData, SectionData } from 'store/menuDetailsStore';
import { actionCreators, OrderDetailsItem } from 'store/ordersStore';
import { ApplicationState } from 'store';
import { OrderDishStatus } from 'constants/enums';
import { useStyles } from '../styles';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props: MenuProps) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

interface DishCardProps {
    dish: DishData;
    sections: SectionData[];
    updateAction: any;
    deleteAction: any;
    isDaily: boolean;
    searchString: string;
    isModal?: boolean;
    isSelected?: boolean;
    setSelected?: (id: string) => void;
}

export default function DishCard(props: DishCardProps) {
    const dispatch = useDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [showDishEditDialog, setShowDishEditDialog] = useState(false);
    const classes = useStyles();
    const [dishToDeleteId, setDishToDeleteId] = useState('');
    const order = useSelector(
        (state: ApplicationState) =>
            state.orders.orders.find((x) => x.id === state.orders.orderDetailsId)!
    );

    const HighlitedText = (text: string, searchWord: string) => (
        <>
            <Highlighter
                highlightClassName={classes.highlighter}
                searchWords={[searchWord]}
                autoEscape={true}
                textToHighlight={text}
            />
        </>
    );

    function handleClick(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(event.currentTarget);
        event.stopPropagation();
        event.preventDefault();
    }

    function handleClose(event: React.MouseEvent<HTMLElement>) {
        setAnchorEl(null);
        setShowDishEditDialog(false);
        event?.stopPropagation();
        event?.preventDefault();
    }

    function handleDialogClose(event?: React.MouseEvent<HTMLElement>) {
        setShowDishEditDialog(false);
        event?.stopPropagation();
        event?.preventDefault();
    }

    function handleDishClick() {
        if (props.isModal && !!props.setSelected) {
            props.setSelected(props.dish.id);
        } else {
            setShowDishEditDialog(true);
        }
    }

    function handleAmount(decrease: boolean = false) {
        const targetItem = order.orderItems.find((x) => x.id === props.dish.id);
        const item: OrderDetailsItem = {
            price: props.dish.price,
            name: props.dish.name,
            id: props.dish.id,
            uniqueId: props.dish.uniqueId,
            amount: 1,
            shortDescription: props.dish.shortDescription,
            orderId: order.id,
            isReady: targetItem?.isReady ?? false,
            orderNumber: order.orderNumber,
            comment: order.comment,
            service: order.service,
            allergens: props.dish.allergens,
            ingredients: props.dish.ingredients,
            status: targetItem?.status ?? OrderDishStatus.None,
            categoryId: '',
            categoryName: '',
            dailyMenuId: '',
            dishes: [],
        };
        return dispatch(actionCreators.addItemToOrder(item, decrease ? -1 : 1));
    }

    function prepareAmountCounter() {
        const count = order.orderItems.find((x) => x.id === props.dish.id)?.amount ?? 0;
        return !!count || props.isSelected ? (
            <div className={classes.itemAmountContainer}>
                <div className={classes.itemAmountComponent}>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(true)}>
                        -
                    </div>
                    <div className={classes.itemAmountCount}>{count ?? 0}</div>
                    <div className={classes.itemAmountAction} onClick={() => handleAmount(false)}>
                        +
                    </div>
                </div>
            </div>
        ) : (
            <></>
        );
    }

    return (
        <div style={{ position: 'relative' }}>
            <Card className={classes.dishCardRoot} onClick={handleDishClick}>
                <CardHeader
                    avatar={
                        <Avatar aria-label="recipe">
                            <img
                                src={props.dish.photo ? props.dish.photo : images.icons.coffee}
                                className={props.dish.photo ? classes.coverImg : ''}
                                alt="section_logo"
                            />
                        </Avatar>
                    }
                    action={
                        <>
                            <IconButton
                                aria-controls="customized-menu"
                                aria-haspopup="true"
                                onClick={handleClick}
                                aria-label="settings"
                            >
                                <MoreVertIcon />
                            </IconButton>
                            <StyledMenu
                                id="customized-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <DeleteMenuItem
                                    onDeleteClick={() => {
                                        setDishToDeleteId(props.dish.id);
                                        setShowConfirmation(true);
                                    }}
                                    text={i18n.t('common.deleteItem')}
                                />
                                <DishManagementDialog
                                    sections={props.sections}
                                    action={props.updateAction}
                                    onClose={handleDialogClose}
                                    isOpen={showDishEditDialog}
                                    dish={props.dish}
                                    disabled={props.dish.isDisabled}
                                />
                            </StyledMenu>
                        </>
                    }
                    title={
                        <div className={classes.cardTitle}>
                            {HighlitedText(props.dish.name, props.searchString)}
                        </div>
                    }
                />
                <CardContent>
                    <Typography
                        className={classes.cardDesc}
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        {HighlitedText(props.dish.shortDescription, props.searchString)}
                    </Typography>
                </CardContent>
                <CardActions disableSpacing className={classes.cardBottom}>
                    <div className={classes.spicesContainer}>
                        {props.dish.isSpicy && (
                            <IconButton aria-label="add to favorites">
                                <img src={images.icons.chili} alt="chili-paper" />
                            </IconButton>
                        )}
                        {props.dish.isVegetarian && (
                            <IconButton aria-label="add to favorites">
                                <img src={images.icons.leaf} alt="green-leaf" />
                            </IconButton>
                        )}
                        {props.dish.isVegan && (
                            <IconButton aria-label="add to favorites">
                                <img src={images.icons.doubleLeaf} alt="vegan" />
                            </IconButton>
                        )}
                        {props.dish.isHalal && (
                            <IconButton aria-label="add to favorites">
                                <img src={images.icons.halal} alt="halal" />
                            </IconButton>
                        )}
                    </div>
                    <IconButton>
                        <div className={classes.cardPrice}>
                            CHF <label className={classes.labelPrice}>{props.dish.price}</label>
                        </div>
                    </IconButton>
                </CardActions>
                <PopupConfirmation
                    open={showConfirmation}
                    close={() => setShowConfirmation(false)}
                    action={() => props.deleteAction(dishToDeleteId)}
                    title={i18n.t('common.deleteItem')}
                    description={i18n.t('confirmation.deleteItem')}
                    activeBtn={i18n.t('button.delete')}
                />
            </Card>
            {props.isModal && prepareAmountCounter()}
        </div>
    );
}

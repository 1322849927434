import { Action, Reducer } from 'redux';
import { AppThunkAction } from '.';

import i18n from 'i18n-js';

import agent, {
    AddExistingDishModel,
    AddSectionModel,
    DeleteDishModel,
    DeleteSectionModel,
    DishCreateModel,
    RenameSectionModel,
    AddSectionDailyMenyModel,
    EditSectionDailyMenyModel,
    IngredientModel,
} from '../api/agent';
import { DaysWithHours } from 'components/menu/MenuManagementTable/components/AddDailyMenuSectionModal';
import globalStore from './globalStore';
import { TaxType } from 'constants/enums';
import { GlobalAction } from './globalStore/actions';

//STATE
export interface MenuDetailsState {
    menu: MenuDetailsData;
}

export interface MenuDetailsData {
    id: string;
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    price: number;
    photo: string;
    type: number;
    sections: SectionData[];
    dishesCount: number;
    isEnabled: boolean;
    position: number;
}

export interface SectionData {
    id: string;
    name: string;
    dishes: DishData[];
    price?: number;
    photo?: string;
    availability?: DaysWithHours;
    searchString?: string;
    isDisabled: boolean;
}

export interface DishData {
    id: string;
    sectionId: string;
    name: string;
    description: string;
    shortDescription: string;
    price: number;
    primeCost?: number;
    photo: string;
    isSpicy: boolean;
    isVegetarian: boolean;
    isVegan: boolean;
    isHalal: boolean;
    isDisabled: boolean;
    sectionName: string;
    sections: SectionData[];
    addToGallery: boolean;
    categoryId: string;
    dailyMenuId: string;
    allergens: string[];
    ingredients: IngredientModel[];
    modifiedIngredients?: { id: string; isAdditionChecked?: boolean; isRemovalChecked?: boolean }[];
    modified?: boolean;
    uniqueId?: string;
    vorortTax?: TaxType;
    homeDeliveryTax?: TaxType;
    takeAwayTax?: TaxType;
    barcode: string;
    stockQuantity: number;
    minimumStock: number;
    notFood: boolean;
}

//ACTIONS
interface MenuGetDetailsStartAction {
    type: 'MENU_GET_DETAILS_START';
}

interface MenuGetDetailsSuccessAction {
    type: 'MENU_GET_DETAILS_SUCCESS';
    menu: MenuDetailsData;
}

interface MenuGetDetailsErrorAction {
    type: 'MENU_GET_DETAILS_ERROR';
}

interface MenuRenameSectionStartAction {
    type: 'MENU_RENAME_SECTION_START';
}

interface MenuRenameSectionSuccessAction {
    type: 'MENU_RENAME_SECTION_SUCCESS';
    menu: SectionData;
}

interface MenuRenameSectionErrorAction {
    type: 'MENU_RENAME_SECTION_ERROR';
}

interface MenuAddNewItemStartAction {
    type: 'MENU_ADD_NEW_ITEM_START';
}

interface MenuAddNewItemSuccessAction {
    type: 'MENU_ADD_NEW_ITEM_SUCCESS';
    dish: DishData;
}

interface MenuAddNewItemErrorAction {
    type: 'MENU_ADD_NEW_ITEM_ERROR';
}

interface AddExistingDishToSection {
    type: 'ADD_EXISTING_DISH_TO_SECTION';
    sectionId: string;
    dish: DishData;
}

interface MenuUpdateItemSuccessAction {
    type: 'MENU_UPDATE_ITEM_SUCCESS';
    dish: DishData;
    sectionId: string;
}

interface DisableDishStart {
    type: 'DISABLE_DISH_START';
}

interface DisableDishSuccess {
    type: 'DISABLE_DISH_SUCCESS';
    dishId: string;
    sectionId: string;
}
interface DisableDishError {
    type: 'DISABLE_DISH_ERROR';
}
interface DisableEnableSectionStart {
    type: 'DISABLE_ENABLE_SECTION_START';
}
interface DisableEnableSectionSuccess {
    type: 'DISABLE_ENABLE_SECTION_SUCCESS';
    payload: string;
}
interface DisableEnableSectionError {
    type: 'DISABLE_ENABLE_SECTION_ERROR';
}
interface EditDailyMenuSectionStart {
    type: 'EDIT_DAILY_MENU_SECTION_START';
}
interface EditDailyMenuSectionSuccess {
    type: 'EDIT_DAILY_MENU_SECTION_SUCCESS';
    id: string;
    name: string;
    price: number;
}
interface EditDailyMenuSectionError {
    type: 'EDIT_DAILY_MENU_SECTION_ERROR';
}

interface MenuSectionsReordertStart {
    type: 'MENU_SECTIONS_REORDER_START';
}

interface MenuSectionsReorderSuccess {
    type: 'MENU_SECTIONS_REORDER_SUCCESS';
    sections: string[];
}

interface MenuSectionsReorderError {
    type: 'MENU_SECTIONS_REORDER_ERROR';
}

interface MenuSectionDishesReordertStart {
    type: 'MENU_SECTION_DISHES_REORDER_START';
}

interface MenuSectionDishesReorderSuccess {
    type: 'MENU_SECTION_DISHES_REORDER_SUCCESS';
    sectionId: string;
    dishes: string[];
}

interface MenuSectionDishesReorderError {
    type: 'MENU_SECTION_DISHES_REORDER_ERROR';
}

//ACTION TYPES
type MenuDetailsAction =
    | GlobalAction
    | MenuGetDetailsStartAction
    | MenuGetDetailsSuccessAction
    | MenuGetDetailsErrorAction
    | MenuRenameSectionStartAction
    | MenuRenameSectionSuccessAction
    | MenuRenameSectionErrorAction
    | MenuAddNewItemStartAction
    | MenuAddNewItemSuccessAction
    | MenuAddNewItemErrorAction
    | AddExistingDishToSection
    | MenuUpdateItemSuccessAction
    | DisableDishStart
    | DisableDishSuccess
    | DisableDishError
    | DisableEnableSectionStart
    | DisableEnableSectionSuccess
    | DisableEnableSectionError
    | EditDailyMenuSectionStart
    | EditDailyMenuSectionSuccess
    | EditDailyMenuSectionError
    | MenuSectionsReordertStart
    | MenuSectionsReorderSuccess
    | MenuSectionsReorderError
    | MenuSectionDishesReordertStart
    | MenuSectionDishesReorderSuccess
    | MenuSectionDishesReorderError;

function instanceOfDailyMenuSection(data: any): data is AddSectionDailyMenyModel {
    return 'availability' in data;
}

//ACTION CREATORS
export const actionCreators = {
    getDetails:
        (id: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_GET_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.GetDetails(id)
                .then((response) => {
                    dispatch({
                        type: 'MENU_GET_DETAILS_SUCCESS',
                        menu: response,
                    });
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_GET_DETAILS_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    renameSection:
        (id: string, model: RenameSectionModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_RENAME_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.RenameSection(id, model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_RENAME_SECTION_SUCCESS',
                        menu: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_RENAME_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    addNewItem:
        (model: DishCreateModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_ADD_NEW_ITEM_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.CreateDish(model)
                .then((response) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_SUCCESS',
                        dish: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.createdSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    updateItem:
        (
            model: DishCreateModel,
            id: string,
            sectionId: string
        ): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_ADD_NEW_ITEM_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.EditDish(model, id)
                .then((response) => {
                    response.sectionId = model.sectionId;
                    dispatch({
                        type: 'MENU_UPDATE_ITEM_SUCCESS',
                        dish: response,
                        sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_ADD_NEW_ITEM_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    addSection:
        (model: AddSectionModel | AddSectionDailyMenyModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            if (instanceOfDailyMenuSection(model)) {
                agent.Menus.AddDailyMenuSection(model as AddSectionDailyMenyModel).then(() => {
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                });
            } else {
                agent.Menus.AddSection(model).then(() => {
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                });
            }
        },
    deleteSection:
        (model: DeleteSectionModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.DeleteSection(model)
                .then(() => {
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    deleteDish:
        (model: DeleteDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.RemoveDish(model)
                .then(() => {
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.deletedSuccesfully')
                    )(dispatch);
                    // @ts-ignore
                    actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    disableDish:
        (model: DeleteDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_DISH_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            const { dishId, sectionId } = model;
            agent.Menus.DisableDish(dishId)
                .then(() => {
                    dispatch({
                        type: 'DISABLE_DISH_SUCCESS',
                        dishId,
                        sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_DISH_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },

    addExistingDish:
        (model: AddExistingDishModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_GET_DETAILS_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.AddExistingDish(model)
                .then(() => {
                    dispatch({
                        type: 'ADD_EXISTING_DISH_TO_SECTION',
                        sectionId: model.sectionId,
                        dish: model.dish,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                    // actionCreators.getDetails(model.menuId)(dispatch);
                })
                .catch((e) => globalStore.actionCreators.showToaster('error', e)(dispatch))
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    disableEnableSection:
        (id: string): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'DISABLE_ENABLE_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.DisableEnableDailyMenuSection(id)
                .then((res) => {
                    dispatch({
                        type: 'DISABLE_ENABLE_SECTION_SUCCESS',
                        payload: res,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'DISABLE_ENABLE_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    editDailyMenuSection:
        (id: string, model: EditSectionDailyMenyModel): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'EDIT_DAILY_MENU_SECTION_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.EditDailyMenuSection(id, model)
                .then((result) => {
                    dispatch({
                        type: 'EDIT_DAILY_MENU_SECTION_SUCCESS',
                        id: result.id,
                        name: result.name,
                        price: result.price,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'EDIT_DAILY_MENU_SECTION_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderSections:
        (ids: string[], isDaily: boolean = false): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTIONS_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ReorderSections(ids, isDaily)
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTIONS_REORDER_SUCCESS',
                        sections: response,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTIONS_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
    reorderDishes:
        (
            sectionId: string,
            ids: string[],
            isDaily: boolean = false
        ): AppThunkAction<MenuDetailsAction> =>
        (dispatch) => {
            dispatch({
                type: 'MENU_SECTION_DISHES_REORDER_START',
            });
            globalStore.actionCreators.showSpiner()(dispatch);
            agent.Menus.ResorderDishes(sectionId, ids, isDaily)
                .then((response) => {
                    dispatch({
                        type: 'MENU_SECTION_DISHES_REORDER_SUCCESS',
                        dishes: response.dishes,
                        sectionId: response.sectionId,
                    });
                    globalStore.actionCreators.showToaster(
                        'success',
                        i18n.t('messages.updatedSuccesfully')
                    )(dispatch);
                })
                .catch((e) => {
                    dispatch({
                        type: 'MENU_SECTION_DISHES_REORDER_ERROR',
                    });
                    globalStore.actionCreators.showToaster('error', e)(dispatch);
                })
                .finally(() => globalStore.actionCreators.hideSpiner()(dispatch));
        },
};

//REDUCER
const initialState: MenuDetailsState = {
    menu: {
        id: '',
        name: '',
        description: '',
        availableFrom: 0,
        availableTo: 0,
        price: 0,
        photo: '',
        type: 0,
        sections: [],
        dishesCount: 0,
        isEnabled: false,
        position: 0,
    },
};

export const reducer: Reducer<MenuDetailsState> = (
    state: MenuDetailsState | undefined,
    incomingAction: Action
): MenuDetailsState => {
    if (state === undefined) {
        return initialState;
    }

    const action = incomingAction as MenuDetailsAction;
    switch (action.type) {
        case 'MENU_GET_DETAILS_START':
            return {
                ...state,
                menu: {
                    id: '',
                    name: '',
                    description: '',
                    availableFrom: 0,
                    availableTo: 0,
                    price: 0,
                    photo: '',
                    type: 0,
                    sections: [],
                    dishesCount: 0,
                    isEnabled: false,
                    position: 0,
                },
            };
        case 'MENU_GET_DETAILS_SUCCESS':
            return {
                ...state,
                menu: action.menu,
            };
        case 'MENU_RENAME_SECTION_SUCCESS':
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.menu.id
                            ? x
                            : ({
                                  id: action.menu.id,
                                  name: action.menu.name,
                                  dishes: x.dishes,
                              } as SectionData)
                    ),
                },
            };
        case 'MENU_ADD_NEW_ITEM_SUCCESS':
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.dish.sectionId
                            ? x
                            : {
                                  ...x,
                                  dishes: [...x.dishes, action.dish],
                              }
                    ),
                },
            };
        case 'ADD_EXISTING_DISH_TO_SECTION': {
            const sectionIndex = state.menu.sections.findIndex(
                (eachSection) => eachSection.id === action.sectionId
            );
            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes.push({
                ...action.dish,
                sectionId: action.sectionId,
            });
            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'MENU_UPDATE_ITEM_SUCCESS': {
            const sectionIndex = state.menu.sections.findIndex(
                (section) => section.id === action.sectionId
            );
            const dishIndex = state.menu.sections[sectionIndex].dishes.findIndex(
                (sectionDish) => sectionDish.id === action.dish.id
            );

            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes[dishIndex] = action.dish;
            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'DISABLE_DISH_SUCCESS': {
            const { dishId, sectionId } = action;

            const sectionIndex = state.menu.sections.findIndex(
                (section) => section.id === sectionId
            );
            const dishIndex = state.menu.sections[sectionIndex].dishes.findIndex(
                (sectionDish) => sectionDish.id === dishId
            );
            const newMenu = { ...state.menu };
            newMenu.sections[sectionIndex].dishes[dishIndex].isDisabled =
                !newMenu.sections[sectionIndex].dishes[dishIndex].isDisabled;

            return {
                ...state,
                menu: newMenu,
            };
        }
        case 'DISABLE_ENABLE_SECTION_SUCCESS': {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.payload ? x : { ...x, isDisabled: !x.isDisabled }
                    ),
                },
            };
        }
        case 'EDIT_DAILY_MENU_SECTION_SUCCESS': {
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: state.menu.sections.map((x) =>
                        x.id !== action.id ? x : { ...x, name: action.name, price: action.price }
                    ),
                },
            };
        }
        case 'MENU_SECTIONS_REORDER_SUCCESS': {
            const sectionsCopy = [...state.menu.sections];
            sectionsCopy.sort(
                (a, b) => action.sections.indexOf(a.id) - action.sections.indexOf(b.id)
            );
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: sectionsCopy,
                },
            };
        }
        case 'MENU_SECTION_DISHES_REORDER_SUCCESS': {
            const sectionCopy = {
                ...state.menu.sections.find((sec) => sec.id === action.sectionId)!,
            };
            sectionCopy.dishes.sort(
                (a, b) => action.dishes.indexOf(a.id) - action.dishes.indexOf(b.id)
            );
            const sections = state.menu.sections.map((sec) =>
                sec.id === sectionCopy.id ? sectionCopy : sec
            );
            return {
                ...state,
                menu: {
                    ...state.menu,
                    sections: sections,
                },
            };
        }
        default:
            return state;
    }
};

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import i18n from 'i18n-js';
import {
    ListItem,
    ListItemText,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Button,
    TableSortLabel,
} from '@material-ui/core';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { FilterNone } from '@material-ui/icons';
import clsx from 'clsx';

import { actionCreators as restaurantsActions } from 'store/restaurantsStore';
import { ApplicationState } from 'store';
import logo from 'assets/restaurant-logo.svg';
import { RestaurantFilterModel } from 'api/agent';
import { CellWithAction } from './CellWithAction';
import {
    BasicThreeDotsMenu,
    SearchInput,
    PopupConfirmation,
    NothingFoundMessage,
    TableToolbar,
} from 'components/shared';
import { RestaurantsCuisinesModal } from '../../RestaurantsCuisinesModal';
import commonStyles from 'config/commonStyles';
import { RestaurantData } from 'store/restaurantsStore';
import { pagingOptions } from 'constants/arrays';
import { HeadCell } from 'constants/interfaces';

const useStyles = makeStyles({
    ...commonStyles,
    root: {
        height: 650,
    },
    logoContainer: {
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        height: 32,
        width: 32,
        borderRadius: 100,
        marginRight: 16,
    },
    disabledRow: {
        '& td:not(:last-child)': {
            opacity: 0.35,
        },
        '& td:last-child': {
            borderBottomColor: 'rgba(224, 224, 224, 0.35)',
        },
    },
    tableContainer: {
        filter: 'none',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
});

export default function RestaurantsTable() {
    const dispatch = useDispatch();
    const history = useHistory();
    const classes = useStyles();
    const restaurantsState = useSelector((state: ApplicationState) => state.restaurants);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(pagingOptions[0]);
    const [showCuisines, setShowCuisines] = useState(false);
    const [orderBy, setOrderBy] = useState('name');
    const [asc, setAsc] = useState(true);
    const [selectedRestaurant, setSelectedRestaurant] = useState<RestaurantData | null>(null);

    const headCells: HeadCell[] = [
        { id: 'name', label: 'Restaurant name' },
        { id: 'address', label: 'Address' },
        { id: 'zip', label: 'ZIP Code' },
        { id: 'city', label: 'City' },
    ];
    const defaultFilter: RestaurantFilterModel = {
        name: '',
        addressLine: '',
        zip: '',
        city: '',
    };
    const [filter, setFilter] = useState(defaultFilter);

    useEffect(() => {
        dispatch(restaurantsActions.getRestaurants(defaultFilter, page, rowsPerPage));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFilterChange = () => {
        setPage(0);
        dispatch(restaurantsActions.getRestaurants(filter, 0, rowsPerPage));
    };
    function handleOrder(id: string) {
        if (orderBy === id) {
            setAsc(!asc);
            dispatch(restaurantsActions.getRestaurants(filter, page, rowsPerPage, !asc, orderBy));
        } else {
            dispatch(restaurantsActions.getRestaurants(filter, page, rowsPerPage, !asc, id));
            setOrderBy(id);
        }
    }
    function handleChangePage(event: unknown, newPage: number) {
        setPage(newPage);
        dispatch(restaurantsActions.getRestaurants(filter, newPage, rowsPerPage, !asc, orderBy));
    }

    function handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        let newRowsPerPage = +event.target.value;
        setRowsPerPage(newRowsPerPage);
        setPage(0);
        dispatch(restaurantsActions.getRestaurants(filter, 0, newRowsPerPage, !asc, orderBy));
    }

    function handleCuisineModal() {
        setShowCuisines(!showCuisines);
    }

    function clearFilterField(value: keyof RestaurantFilterModel) {
        if (filter.hasOwnProperty(value)) {
            const newFilter = { ...filter, [value]: '' };
            setFilter(newFilter);
            dispatch(restaurantsActions.getRestaurants(newFilter, page, rowsPerPage));
        }
    }

    function switchRestaurantStatus() {
        selectedRestaurant &&
            dispatch(restaurantsActions.switchRestaurantStatus(selectedRestaurant));
        setSelectedRestaurant(null);
    }

    return (
        <>
            <TableToolbar
                rightActions={[
                    <Button variant="outlined" color="primary" onClick={handleCuisineModal}>
                        {i18n.t('restaurant.typesOfCuisine')}
                    </Button>,
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => history.push('/createrestaurant')}
                    >
                        <span>+</span>{' '}
                        <span style={{ marginLeft: 12 }}>{i18n.t('restaurant.newRestaurant')}</span>
                    </Button>,
                ]}
            />
            <Paper className={classes.tableContainer}>
                <TableContainer className={classes.root}>
                    <Table stickyHeader className={classes.table} aria-label="simple table">
                        <TableHead className={classes.tableHead}>
                            <TableRow key="table-head">
                                <TableCell sortDirection="asc">
                                    <TableSortLabel
                                        hideSortIcon={false}
                                        active={orderBy === headCells[0].id}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => {
                                            handleOrder(headCells[0].id);
                                        }}
                                    >
                                        <SearchInput
                                            value={filter.name}
                                            name={i18n.t('form.restaurantName')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, name: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('name')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection="asc">
                                    <TableSortLabel
                                        active={orderBy === headCells[1].id}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(headCells[1].id)}
                                    >
                                        <SearchInput
                                            value={filter.addressLine}
                                            name={i18n.t('form.restaurantAddress')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, addressLine: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('addressLine')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection="asc" width={150}>
                                    <TableSortLabel
                                        active={orderBy === headCells[2].id}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(headCells[2].id)}
                                    >
                                        <SearchInput
                                            value={filter.zip}
                                            name={i18n.t('form.zip')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, zip: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('zip')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell sortDirection="asc" width={150}>
                                    <TableSortLabel
                                        active={orderBy === headCells[3].id}
                                        direction={asc ? 'asc' : 'desc'}
                                        onClick={() => handleOrder(headCells[3].id)}
                                    >
                                        <SearchInput
                                            value={filter.city}
                                            name={i18n.t('form.city')}
                                            onChange={(value: string) =>
                                                setFilter({ ...filter, city: value })
                                            }
                                            onEnter={handleFilterChange}
                                            onReset={() => clearFilterField('city')}
                                        />
                                    </TableSortLabel>
                                </TableCell>
                                <TableCell width={20}></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {restaurantsState?.restaurants?.length > 0 ? (
                                restaurantsState?.restaurants?.map((row) => (
                                    <TableRow
                                        key={row.id}
                                        className={clsx({
                                            [classes.disabledRow]: row.isDisabled,
                                        })}
                                    >
                                        <CellWithAction
                                            id={row.id}
                                            content={
                                                <ListItem alignItems="center">
                                                    <div
                                                        className={classes.logoContainer}
                                                        style={{
                                                            backgroundImage: `url(${
                                                                row.logoUrl ? row.logoUrl : logo
                                                            })`,
                                                        }}
                                                    ></div>

                                                    <ListItemText primary={row.name} />
                                                </ListItem>
                                            }
                                        />
                                        <CellWithAction id={row.id} content={row.addressLine} />
                                        <CellWithAction id={row.id} content={row.zip} />
                                        <CellWithAction id={row.id} content={row.city} />
                                        <TableCell align="right">
                                            <BasicThreeDotsMenu
                                                items={[
                                                    <div
                                                        className={
                                                            classes.threeDotsMenuItemContainer
                                                        }
                                                        onClick={() => setSelectedRestaurant(row)}
                                                    >
                                                        {!row.isDisabled ? (
                                                            <RemoveCircleOutlineIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                                className={
                                                                    classes.threeDotsItemIcon
                                                                }
                                                            />
                                                        ) : (
                                                            <SettingsBackupRestoreIcon
                                                                fontSize="small"
                                                                color="disabled"
                                                                className={
                                                                    classes.threeDotsItemIcon
                                                                }
                                                            />
                                                        )}
                                                        <ListItemText
                                                            primary={i18n.t(
                                                                `${
                                                                    row.isDisabled
                                                                        ? 'button.restore'
                                                                        : 'common.disable'
                                                                }`
                                                            )}
                                                        />
                                                    </div>,
                                                    <div
                                                        className={
                                                            classes.threeDotsMenuItemContainer
                                                        }
                                                        onClick={() =>
                                                            history.push(`/restaurants/${row.id}`)
                                                        }
                                                    >
                                                        <FilterNone
                                                            className={classes.threeDotsItemIcon}
                                                        />
                                                        <ListItemText
                                                            primary={i18n.t('common.details')}
                                                        />
                                                    </div>,
                                                ]}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={5} style={{ height: 550 }}>
                                        <NothingFoundMessage />
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>

                    <PopupConfirmation
                        open={!!selectedRestaurant}
                        close={() => setSelectedRestaurant(null)}
                        activeBtn={
                            selectedRestaurant?.isDisabled
                                ? i18n.t('button.restore')
                                : i18n.t('common.disable')
                        }
                        description={
                            selectedRestaurant?.isDisabled
                                ? i18n.t('confirmation.sureToRestoreRestaurant')
                                : i18n.t('confirmation.sureToDisableRestaurant')
                        }
                        title={
                            selectedRestaurant?.isDisabled
                                ? i18n.t('restaurant.restoreRestaurant')
                                : i18n.t('restaurant.disableRestaurant')
                        }
                        action={switchRestaurantStatus}
                    />
                    <RestaurantsCuisinesModal onClose={handleCuisineModal} isOpen={showCuisines} />
                </TableContainer>
                <TablePagination
                    className={classes.paging}
                    rowsPerPageOptions={pagingOptions}
                    component="div"
                    count={restaurantsState.count}
                    rowsPerPage={rowsPerPage}
                    labelRowsPerPage={i18n.t('common.rowsPerPage')}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </>
    );
}

import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import i18n from 'i18n-js';

import userManager from 'managers/userManager';
import { RestaurantCreateModel } from 'store/restaurantsStore';
import {
    AdminCreateModel,
    AdminEditModel,
    RestaurantUserCreateModel,
    RestaurantUserEditModel,
} from 'store/usersStore';
import { DishData } from 'store/menuDetailsStore';
import { QRData, RestaurantDetails } from 'store/restaurantDetailsStore';
import { CreateCategoryModel } from 'store/categoryStore/reducer';
import {
    MenuType,
    OrderDishStatus,
    PaymentMethod,
    OrderServices,
    OrderStatus,
    TaxType,
} from 'constants/enums';
import { OrderItem } from 'store/ordersStore';
import { DaysWithHours } from 'components/menu/MenuManagementTable/components/AddDailyMenuSectionModal';

axios.defaults.baseURL = process.env.REACT_APP_API;
const identityUrl = process.env.REACT_APP_IDENTITY;
userManager.getUser().then((user) => {
    if (user && !user.expired) {
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + user.access_token;
        //axios.defaults.headers.common['timezone'] = getBrowserTimezone();
    }
});

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (!error.response) {
            return Promise.reject(i18n.t('warnings.connectionError'));
        } else if (400 === error.response.status) {
            return Promise.reject(i18n.t('warnings.wrongDataProvided'));
        } else if (401 === error.response.status) {
            userManager.signoutRedirect();
            userManager.removeUser();
            return Promise.reject(i18n.t('warnings.sessionExpired'));
        } else if (404 === error.response.status) {
            return Promise.reject(i18n.t('warnings.pageNotFound'));
        } else if (409 === error.response.status) {
            return Promise.reject(error.response.data);
        } else {
            return Promise.reject(i18n.t('common.somethingWrong'));
        }
    }
);

function prepareHeaders(noJson: boolean = false) {
    return {
        headers: noJson
            ? {
                  'Accept-Language': localStorage.getItem('jamesLanguage') || 'de',
              }
            : {
                  'Content-Type': 'application/json;charset=UTF-8',
                  'Accept-Language': localStorage.getItem('jamesLanguage') || 'de',
              },
    };
}

export interface RestaurantFilterModel {
    name: string;
    addressLine: string;
    zip: string;
    city: string;
}

export interface UserFilterModel {
    name: string;
    restaurant: string;
    email: string;
    phoneNumber: string;
    position: string;
    roles: string[];
    restaurantId?: string;
}

export interface MenuCreateModel {
    name: string;
    description: string;
    availableFrom: number;
    availableTo: number;
    photo: string;
    type: MenuType;
    price?: number;
    position: number;
}

export interface MenuDetailsData {
    menuId: string;
    sectionId: string;
    sectionName: string;
}

export interface RenameSectionModel {
    name: string;
}

export interface AddSectionModel {
    name: string;
    menuId: string;
}

export interface AddSectionDailyMenyModel extends AddSectionModel {
    price: number;
    availability: DaysWithHours;
}

export interface EditSectionDailyMenyModel {
    name: string;
    price: number;
    photo: string;
    availability: DaysWithHours;
}

export interface DeleteSectionModel {
    menuId: string;
    sectionId: string;
}

export interface DeleteDishModel {
    dishId: string;
    sectionId: string;
    menuId: string;
}

export interface AddExistingDishModel {
    dishId: string;
    sectionId: string;
    dish: DishData;
}

export interface EditDishModel {
    dishId: string;
    sectionId: string;
    menuId: string;
}

export interface DishCreateModel {
    name: string;
    description: string;
    shortDescription: string;
    price: number | null;
    photo: string;
    isSpicy: boolean;
    isVegetarian: boolean;
    isVegan: boolean;
    isHalal: boolean;
    sectionId: string;
    kitchenId: string;
    addToGallery?: boolean;
    categoryId: string;
    allergens: string[];
    ingredients: IngredientModel[];
    primeCost: number;
    vorortTax: TaxType;
    homeDeliveryTax: TaxType;
    takeAwayTax: TaxType;
    barcode: string;
    stockQuantity: number;
    minimumStock: number;
    notFood: boolean;
}

export interface RestaurantTypesManagementModel {
    create: string[];
    delete: string[];
    rename: { id: string; name: string }[];
}

export interface IdModel {
    id: string;
}

export interface OrderItemStatusChangeModel {
    orderId: string;
    itemId: string;
    dailyMenuId: string;
    status?: OrderDishStatus;
}

export interface PositionOrderItemStatusChangeModel {
    orderId: string;
    itemId: string;
    status: OrderDishStatus;
    previousStatus: OrderDishStatus;
    dailyMenuId: string;
}

export interface OrderUpdateModel {
    orderItems: OrderItem[];
    deliveryTime: number;
    status: OrderStatus;
    paymentMethod: PaymentMethod;
    qrCodeName: string;
    service: OrderServices;
}

export interface UsersToRestaurantUserModel {
    restaurantId: string;
    restaurantName: string;
    usersIds: string[];
    isContactPersons: boolean;
}

export interface EmptyOrderModel {
    service: string;
    deliveryTime: number;
    name: string;
    city: string;
    addressLine: string;
    email: string;
    phoneNumber: string;
    comment: string;
    qrCodeName: string;
}

export interface RestaurantPaymentDetailsModel {
    firmName: string;
    firstName: string;
    lastName: string;
    address: string;
    zip: string;
    city: string;
    accountNumber: string;
    iban: string;
    vat: string;
    tax: number;
    currency: string;
    thanksMessage: string;
}

export enum DishIngredientsState {
    Fixed,
    Addition,
}

export interface IngredientModel {
    id?: string;
    name: string;
    price: number;
    categoryId: string;
    amount: number;
    unitOfMeasure: string;
    allergens: string[];
    isAddition?: boolean;
    isRemoval?: boolean;
    isAdditionChecked?: boolean;
    isRemovalChecked?: boolean;
    isAdditionCheckedModified?: boolean;
    isRemovalCheckedModified?: boolean;
    state?: number;
    modified?: boolean;
}

export interface UpdateIngredientModel {
    name: string;
    price: number;
    categoryId: string;
    amount: number;
    unitOfMeasure: string;
    id: string;
    lang: string;
    allergens: string[];
}

export interface UpdateCategoryModel {
    id: string;
    lang: 'en' | 'de';
    name: string;
}

export interface SectionEditModel {
    name: string;
    price: number;
}

export interface TaxRequestModel {
    standardRate: number;
    reducedRate: number;
    specialRateForAccommodation: number;
}

export interface TaxResponseModel extends TaxRequestModel {
    id: string;
}

const responseBody = (response: AxiosResponse) => response.data;

const requests = {
    get: (url: string, params?: {}) =>
        axios
            .get(url, {
                ...params,
                ...prepareHeaders(true),
            })
            .then(responseBody),
    post: (url: string, body: {} = {}) =>
        axios.post(url, body, prepareHeaders()).then(responseBody),
    put: (url: string, body: {}, config: AxiosRequestConfig | undefined) =>
        axios.put(url, body, config).then(responseBody),
    delete: (url: string, data?: {}) =>
        axios
            .delete(url, {
                data,
            })
            .then(responseBody),
};

const IdentityServer = {
    SignIn: (email: string, password: string) => requests.get(''),
    SignUp: (email: string, password: string) => requests.post('', { email, password }),
};

const Restaurants = {
    Create: (model: RestaurantCreateModel) =>
        requests.put('/Restaurant/create', model, prepareHeaders()),
    Update: (id: string, model: RestaurantCreateModel) => requests.post(`Restaurant/${id}`, model),
    GetList: (
        filterModel: RestaurantFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        axios
            .get(
                '/Restaurant/list',
                filterModel != null
                    ? {
                          params: {
                              Name: filterModel!.name,
                              AddressLine: filterModel!.addressLine,
                              ZIP: filterModel!.zip,
                              City: filterModel!.city,
                              page: page,
                              limit: limit,
                              asc: asc,
                              orderBy: orderBy,
                          },
                      }
                    : {
                          params: {
                              page: page,
                              limit: limit,
                              asc: asc,
                              orderBy: orderBy,
                          },
                      }
            )
            .then(responseBody),
    GetDetails: (id: string) => requests.get(`Restaurant/${id}`),
    GetRestaurantInformation: () => requests.get('Restaurant/getdetails'),
    UpdateRestaurantInformation: (model: RestaurantDetails) =>
        axios.post('Restaurant/updatedetails', model, prepareHeaders()),
    GetCuisineTypes: () => axios.get('Restaurant/types'),
    CreateCuisineType: (model: { name: string }) =>
        requests.put('Restaurant/types', model, prepareHeaders()),
    ManageCuisineTypes: (model: RestaurantTypesManagementModel) =>
        requests.post('Restaurant/types', model),
    GetQrs: (params = {}) => agent.requests.get('Restaurant/qrcodes', { params }),
    CreateQrCode: (qrCode: QRData, params = {}) =>
        agent.requests.put('Restaurant/qrcodes', qrCode, { ...prepareHeaders(), params }),
    UpdateQrCode: (qrCode: QRData) => agent.requests.post('Restaurant/qrcodes', qrCode),
    DeleteQrCode: (qrIds: string[]) => agent.requests.delete('Restaurant/qrcodes', qrIds),
    GetFinance: (dateFrom: any, dateTo: any, restaurantId = '') =>
        agent.requests.get('Restaurant/finance', {
            params: {
                dateFrom,
                dateTo,
                restaurantId,
            },
        }),

    DisableRestaurant: (id: string) => agent.requests.post(`Restaurant/disable/${id}`),
    UpdatePaymentDetails: (model: RestaurantPaymentDetailsModel) =>
        agent.requests.post('Restaurant/paymentdetails', model),
    UpdateQrCodesName: (name: string) =>
        agent.requests.post('Restaurant/qrcodes/name', { name: name }),
    GetTaxes: () => agent.requests.get('Restaurant/taxes'),
    UpdateTaxes: (id: string, model: TaxRequestModel) =>
        agent.requests.post(`Restaurant/taxes/${id}`, model),
};

const Users = {
    GetList: (
        filterModel: UserFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        axios
            .post(
                `/User/list?page=${page}&limit=${limit}&asc=${asc}&orderby=${orderBy}`,
                filterModel,
                prepareHeaders()
            )
            .then(responseBody),
    GetRestaurantUsersList: (
        filterModel: UserFilterModel | null = null,
        page?: number,
        limit?: number,
        asc?: boolean,
        orderBy?: string
    ) =>
        axios
            .post(
                `/User/restaurantusers?page=${page}&limit=${limit}&asc=${asc}&orderby=${orderBy}`,
                filterModel,
                prepareHeaders()
            )
            .then(responseBody),
    CreateAdmin: (model: AdminCreateModel) =>
        axios
            .put(`${identityUrl}/Account/RegisterAdmin`, model, prepareHeaders())
            .then(responseBody),
    CreateRestaurantOwner: (model: RestaurantUserCreateModel) =>
        axios
            .put(`${identityUrl}/Account/RegisterRestaurantOwner`, model, prepareHeaders())
            .then(responseBody),
    CreateRestaurantOwners: (model: RestaurantUserCreateModel[]) =>
        axios
            .put(`${identityUrl}/Account/RegisterRestaurantOwner`, model, prepareHeaders())
            .then(responseBody),
    CreateRestaurantEmployee: (model: RestaurantUserCreateModel) =>
        axios
            .put(`${identityUrl}/Account/RegisterRestaurantEmployee`, model, prepareHeaders())
            .then(responseBody),
    CreateRestaurantEmployees: (model: RestaurantUserCreateModel[]) =>
        axios
            .put(`${identityUrl}/Account/RegisterRestaurantEmployee`, model, prepareHeaders())
            .then(responseBody),
    EditAdmin: (model: AdminEditModel) =>
        axios.post(`${identityUrl}/Account/EditAdmin`, model, prepareHeaders()).then(responseBody),
    EditRestaurantUser: (model: RestaurantUserEditModel) =>
        axios
            .post(`${identityUrl}/Account/EditRestaurantUser`, model, prepareHeaders())
            .then(responseBody),
    DisableUsers: (ids: string[]) => requests.post(`${identityUrl}/Account/DisableUsers`, ids),
    EnableUsers: (ids: string[]) => requests.post(`${identityUrl}/Account/EnableUsers`, ids),
    FromUsersToRestaurantOwners: (model: UsersToRestaurantUserModel) =>
        requests.post(`${identityUrl}/Account/RegisterRestaurantOwnersFromUsers`, model),
    FromUsersToRestaurantEmployees: (model: UsersToRestaurantUserModel) =>
        requests.post(`${identityUrl}/Account/RegisterRestaurantEmployeesFromUsers`, model),
    GetResponsibleUsers: () => requests.get('/User/responsibleusers'),
};

const Menus = {
    Create: (menuModel: MenuCreateModel) =>
        requests.put('/Menu/create', menuModel, prepareHeaders()),
    GetList: (params = {}) => requests.get('/Menu/list', { params }),
    Disable: (id: string) => requests.get(`/Menu/disable/${id}`),
    Delete: (id: string) => requests.post('/Menu/delete', { id }),
    GetDetails: (id: string) => requests.get(`/Menu/${id}`),
    RenameSection: (id: string, model: RenameSectionModel) =>
        requests.post(`/Menu/renameSection?id=${id}`, model),
    AddSection: (model: AddSectionModel) => requests.post('/Menu/addSection', model),
    AddDailyMenuSection: (model: AddSectionDailyMenyModel) =>
        requests.post('/Menu/addDailyMenySection', model),
    DeleteSection: (model: DeleteSectionModel) => requests.post('/Menu/deleteSection', model),
    CreateDish: (model: DishCreateModel) => requests.put('/Dish/create', model, prepareHeaders()),
    RemoveDish: (model: DeleteDishModel) =>
        requests.put('/Dish/removefromsection', model, prepareHeaders()),
    DisableDish: (id: string) =>
        requests.post('/Dish/disable', {
            id,
        }),
    GetDishList: () => requests.get('/Dish/list'),
    AddExistingDish: (model: AddExistingDishModel) =>
        requests.put('/Dish/addexisting', model, prepareHeaders()),
    EditDish: (model: DishCreateModel, id: string) => requests.post(`/Dish/update/${id}`, model),
    Edit: (menuModel: MenuCreateModel, id: string) => requests.post(`/Menu/${id}`, menuModel),
    GetMenusDetails: (id?: string) => requests.get(`/Menu/getMenusDetails${id ? '/' + id : ''}`),
    DeleteDish: (model: IdModel) => requests.post('/Dish/delete', model),
    GetMenuDishes: (id: string) => requests.get(`/Menu/getMenuDishes/${id}`),
    DisableEnableDailyMenuSection: (id: string) =>
        requests.post(`/Menu/disableEnableDailyMenuSection?id=${id}`),
    EditDailyMenuSection: (id: string, model: EditSectionDailyMenyModel) =>
        requests.post(`/Menu/updateDailyMenuSection?id=${id}`, model),
    ReorderMenus: (ids: string[]) => requests.post('/Menu/reorder', ids),
    ReorderSections: (ids: string[], isDaily: boolean = false) =>
        requests.post(`/Menu/sections/reorder?isDaily=${isDaily}`, ids),
    ResorderDishes: (sectionId: string, ids: string[], isDaily: boolean = false) =>
        requests.post(`/Menu/sections/${sectionId}/reorderDishes?isDaily=${isDaily}`, ids),
};

const Roles = {
    GetAll: () => axios.get('/Role/all').then(responseBody),
};

const Common = {
    UploadImage: (fileFormData: any) => agent.requests.post(`/File/uploadimage`, fileFormData),
};

const Category = {
    GetCategoryDetails: (asc: boolean, orderBy: string) =>
        agent.requests.get(`/Category?asc=${asc}&orderBy=${orderBy}`),
    CreateCategory: (model: CreateCategoryModel) =>
        agent.requests.put('/Category/Create', model, prepareHeaders()),
    EditCategory: (model: CreateCategoryModel, id: string) =>
        agent.requests.post(`/Category/${id}`, model),
    DisableOrEnableCategory: (ids: string[]) => agent.requests.post(`/Category/changestatus`, ids),
};

const Order = {
    GetOrdersList: (
        searchString: string = '',
        dateFrom: string | undefined = undefined,
        dateTo: string | undefined = undefined,
        page: number,
        limit: number,
        asc: boolean,
        orderBy: string
    ) =>
        agent.requests.get(
            `/Order/list?searchString=${searchString}&dateFrom=${dateFrom ?? ''}&dateTo=${
                dateTo ?? ''
            }&page=${page}&limit=${limit}&asc=${asc}&orderBy=${orderBy}`
        ),
    ChangeOrderItemStatus: (model: OrderItemStatusChangeModel) =>
        agent.requests.post(`/Order/changeitemstatus`, model),
    UpdateOrder: (id: string, model: OrderUpdateModel) =>
        agent.requests.post(`/Order/${id}`, model),
    GetOrder: (id: string) => agent.requests.get(`/Order/${id}`),
    GetNewOrdersCount: (position: string) =>
        agent.requests.get(`/Order/getneworderscount?position=${position}`),
    GetPositionOrders: (position: string) =>
        agent.requests.get(`/Order/getpositionorders?position=${position}`),
    CreateEmptyOrder: (model: EmptyOrderModel) =>
        agent.requests.put('/Order/createempty', model, prepareHeaders()),
    GetReadyDishes: (searchString: string = '', page: number, limit: number) =>
        agent.requests.get(
            `/Order/getreadydishes?searchString=${searchString}&page=${page}&limit=${limit}`
        ),
    GetOverdudedOrders: () => agent.requests.get(`Order/getoverdudedorders`),
    GetOverdudedOrdersDetails: (ordersNumbers: number[]) =>
        agent.requests.get(
            `Order/getoverdudedordersdetails?${ordersNumbers
                .map((x) => 'orderNumber=' + x)
                .join('&')}`
        ),
    AssignOrder: (orderId: string, restaurantId: string, userId: string) =>
        agent.requests.post(`/Order/${orderId}/assign`, {
            restaurantId: restaurantId,
            userId: userId,
        }),
};

const User = {
    GetUserRestaurants: () => agent.requests.get('/Restaurant/userrestaurants'),
    SetUserCurrentRestaurant: (id: string) => agent.requests.post(`/User/currentrestaurant/${id}`),
    GetUserProfile: () => agent.requests.get(`${identityUrl}/Account/GetProfile`),
};

const Ingredients = {
    GetIngredients: (lang: 'en' | 'de', page: number, limit: number, getAll: boolean) =>
        agent.requests.get('/Product/ingredient', {
            params: {
                lang,
                page,
                limit,
                getAll,
            },
        }),
    CreateIngredient: (model: IngredientModel) =>
        agent.requests.put('/Product/ingredient', model, prepareHeaders()),
    UpdateIngredient: (model: UpdateIngredientModel) =>
        agent.requests.post(`/Product/ingredient/?id=${model.id}&lang=${model.lang}`, model),
    GetCategories: (lang: 'en' | 'de', page: number, limit: number) =>
        agent.requests.get('/Product/ingredient-category', {
            params: {
                lang,
                page,
                limit,
            },
        }),
    CreateCategory: (name: string) =>
        agent.requests.put(
            '/Product/ingredient-category',
            {
                name,
            },
            prepareHeaders()
        ),
    UpdateCategory: (model: UpdateCategoryModel) =>
        agent.requests.post(
            `/Product/ingredient-category/?id=${model.id}&lang=${model.lang}`,
            model
        ),
    DeleteCategory: (id: string) => agent.requests.delete(`/Product/ingredient-category/?id=${id}`),
    DisableEnable: (id: string) =>
        agent.requests.post(`/Product/ingredient-category/disable-enable/?id=${id}`),
    GetAllergiens: (lang: string = 'de') => agent.requests.get(`/Product/allergen?lang=${lang}`),
};

const agent = {
    requests,
    IdentityServer,
    Restaurants,
    Users,
    Roles,
    Menus,
    Common,
    Category,
    Order,
    User,
    Ingredients,
};

export default agent;

import { alpha, createTheme } from '@material-ui/core';

export const theme = createTheme({
    typography: {
        fontFamily: '-apple-system, BlinkMacSystemFont, Roboto, Segoe UI, sans-serif',
    },
    palette: {
        primary: {
            main: '#99567E',
            dark: '#592A46',
            light: '#FFA899',
        },
        secondary: {
            main: '#B0B0B0',
            dark: '#5B5858',
            light: '#E7E7E7',
        },
        action: {
            active: '#7B9AE9',
        },
        error: {
            main: 'rgba(198, 40, 40, 1)',
            dark: '#C62828',
            light: '#D45336',
        },
        background: {
            default: 'rgba(153, 86, 126, 0.16)',
        },
        grey: {
            '200': '#d6d6d6',
            '300': '#E5E5E5',
            '400': '#b0b0b0',
            '600': '#9E9E9E',
            '800': '#303030',
        },
        warning: {
            main: '#E1BA2E',
            light: '#EFE4EA',
        },
        success: {
            main: '#5FCF55',
            light: '#e5f8e4',
        },
        info: {
            main: '#04A3D4',
            dark: '#4432A4',
        },
        text: {
            primary: 'rgba(0, 0, 0, 0.87)',
            secondary: 'rgba(0, 0, 0, 0.6)',
            disabled: 'rgba(0, 0, 0, 0.54)',
        },
    },
    props: {},
});
//move here for using theme palette colors in overrides
theme.overrides = {
    MuiContainer: {
        root: {
            width: '100%',
            paddingLeft: 0,
            paddingRight: 0,
            '@media (min-width: 600px)': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
        maxWidthXs: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        maxWidthSm: {
            paddingLeft: 0,
            paddingRight: 0,
        },
        maxWidthMd: {
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.palette.common.white,
        },
        maxWidthLg: {
            //width: 1115,
            width: '98%',
            height: '98%',
            //maxHeight: 776,
            paddingLeft: 0,
            paddingRight: 0,
            backgroundColor: theme.palette.common.white,
            //marginBottom: 20,
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            '@media (min-width: 1280px)': {
                maxWidth: '98%',
            },
        },
        maxWidthXl: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    MuiInputBase: {
        root: {
            // backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
    },
    MuiFilledInput: {
        root: {
            // backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },
        underline: {},
        error: {
            '&:after': {
                borderBottomWidth: '3px',
            },
        },
        disabled: {
            '&:before': {
                borderBottomStyle: 'solid!important',
            },
        },
    },
    MuiFormHelperText: {
        root: {},
        error: {},
        // This hides the error text for inputs which should be hidden by the design
        contained: {
            display: 'none',
        },
    },
    MuiButton: {
        // Name of the component ⚛️ / style sheet
        containedSecondary: {
            // Name of the rule
            color: '#2C61EB',
        },
        containedPrimary: {
            color: theme.palette.common.white,
        },
        outlinedSecondary: {
            color: '#7B9AE9',
            border: 'none',
        },
    },
    MuiIconButton: {
        root: {
            padding: 0,
            color: theme.palette.primary.main,
        },
    },
    MuiTableSortLabel: {
        root: {
            flexDirection: 'row-reverse',
            '& svg': {
                opacity: 0.3,
                marginLeft: -2,
            },
            '& .search-input ~ svg, & .MuiFormControl-root ~ svg': {
                opacity: 0.3,
                marginTop: 27,
                marginLeft: -2,
            },
        },
        icon: {
            marginLeft: 0,
        },
    },
    MuiTableHead: {
        root: {
            '& .MuiTableCell-root': {
                paddingTop: 0,
            },
        },
    },
    MuiTableBody: {
        root: {
            borderCollapse: 'separate',
            '& .MuiTableRow-root:hover': {
                // backgroundColor: alpha(theme.palette.secondary.light, 0.3),
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
            '& .MuiListItem-gutters': {
                padding: 0,
            },
            '& .MuiAvatar-root': {
                width: 32,
                height: 32,
            },
        },
    },
    MuiTableCell: {
        body: {
            paddingTop: '8px',
            paddingBottom: '8px',
            color: theme.palette.text.secondary,
            '&.MuiTypography-root, &.MuiTableCell-root:first-child': {
                fontSize: 14,
                color: theme.palette.text.primary,
            },
        },
        stickyHeader: {
            backgroundColor: theme.palette.common.white,
        },
    },
    MuiChip: {
        label: {
            color: theme.palette.common.white,
        },
    },
    MuiDialog: {
        paperWidthXs: {
            width: 371,
        },
        paperWidthSm: {
            width: 468,
            '& .MuiTypography-h6, .MuiDialogContentText-root': {
                textAlign: 'start',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
            },
            '& .MuiTextField-root, button': {
                marginBottom: 28,
            },
            '&.MuiPickersModal-dialogRoot': {
                width: 310,
            },
            '&.MuiDialog-paperWidthSm :not(.MuiDialogActions-root) button': {
                margin: 0,
            },
            '& .MuiButton-label .MuiPickersToolbarText-toolbarTxt, & .MuiPickerDTToolbar-separator, & .MuiPickerDTTabs-tabs':
                {
                    color: theme.palette.common.white,
                },
            '& .MuiPickersModal-dialog + div button:nth-child(1)': {
                color: '#9E9E9E',
            },
            '& .MuiPickersModal-dialog + div button:nth-child(2)': {
                color: 'rgba(44,97,235,1)',
            },
            '& button': {
                marginLeft: 10,
            },
            '& .Form-actions': {
                marginBottom: 20,
            },
            '& .Form-actions button': {
                marginLeft: '10px !important',
            },
        },
        paperWidthMd: {
            width: 750,
        },
        paperWidthLg: {
            width: 936,
        },
    },
    MuiDialogContent: {
        root: {
            overflowY: 'hidden',
        },
    },
    MuiDialogActions: {
        root: {
            marginBottom: 20,
            padding: 0,
            '& button': {
                marginLeft: '15px !important',
            },
        },
    },
    MuiCheckbox: {
        colorSecondary: {
            '&$checked': {
                color: theme.palette.primary.main,
            },
        },
    },
    MuiTab: {
        root: {
            padding: '6px 20px',
            '@media (min-width: 600px)': {
                minWidth: 72,
            },
        },
    },
    MuiTableContainer: {
        root: {
            display: 'flex',
            flexDirection: 'column',
            //flexGrow: 1,
            //maxHeight: 656,
        },
    },
    MuiTable: {
        root: {
            borderCollapse: 'separate',
            '& th:last-child': {
                padding: 0,
            },
            '& .MuiTableCell-head': {
                paddingRight: 0,
            },
            '& .MuiToolbar-gutters': {
                padding: '0px !important',
            },
            '& .MuiTableCell-body': {
                paddingTop: 5,
                paddingBottom: 5,
            },
            '& .MuiTableCell-root': {
                paddingLeft: 10,
                paddingRight: 10,
            },
            '& .MuiListItem-gutters': {
                padding: '0 !important',
            },
            '& .MuiGrid-spacing-xs-2': {
                margin: 0,
            },
        },
        stickyHeader: {
            backgroundColor: theme.palette.common.white,
        },
    },
    MuiTablePagination: {
        root: {
            overflow: 'hidden',
        },
        selectIcon: {
            color: theme.palette.text.disabled,
        },
        actions: {
            '& .MuiButtonBase-root:first-child': {
                marginRight: 10,
            },
        },
        toolbar: {
            height: '32px !important',
            marginTop: '0px ! important' /*
            '& .MuiTablePagination-spacer+p': {
                display: 'none',
            }, */,
        },
        spacer: {
            display: 'none',
        },
        select: {
            paddingTop: 10,
        },
    },
    MuiList: {
        root: {
            width: 250,
            padding: 0,
            minHeight: 30,
            '& .MuiListItemIcon-root': {
                marginRight: 16,
                minWidth: 'unset',
            },
            '& > li:not(:last-child)': {
                borderBottom: `1px solid ${theme.palette.grey[300]}`,
            },
        },
    },
    MuiListItem: {
        root: {
            '&$selected': {
                backgroundColor: alpha(theme.palette.primary.main, 0.16),
            },
        },
    },
    MuiPaper: {
        root: {
            /* display: 'flex',
            flexGrow: 1, */
            '& .MuiToolbar-root': {
                minHeight: 'auto',
                marginTop: 14,
                paddingLeft: 26,
                paddingRight: 18,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
            },
        },
        elevation1: {
            boxShadow: 'none',
            //filter: 'drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.08))',
        },
    },
    MuiTooltip: {
        tooltip: {
            backgroundColor: theme.palette.text.disabled,
        },
    },
    MuiMenuItem: {
        root: {
            '@media (min-width: 600px)': {
                minHeight: 48,
            },
        },
    },
    MuiToolbar: {
        root: {
            minHeight: '23px !important',
            height: '23px !important',
            padding: '16px !important',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
    },
    MuiSelect: {
        select: {
            '&:focus': {
                backgroundColor: 'transparent',
            },
        },
        icon: {
            color: theme.palette.primary.main,
        },
    },
    MuiAccordion: {
        root: {
            filter: 'none',
        },
    },
    MuiAccordionSummary: {
        root: {
            minHeight: 'unset',
            '&.Mui-expanded': {
                minHeight: 'unset',
            },
            filter: 'none',
        },
        content: {
            '&.Mui-expanded': {
                margin: '12px 0',
            },
        },
        expanded: {
            minHeight: 'unset',
        },
    },
};
